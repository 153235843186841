import React, { useState, useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import "../../index.css";

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import dimage from "../../images/document.jpg";
import Cookies from "js-cookie";
import axios from "axios";

const Documents = () => {
  // Initial state
  const [loading, setLoading] = useState(false);
  const [myProfile, setMyProfile] = useState([]);
  const [dStatus, setDStatus] = useState(0);
  const [proposalAmount, setProposalAmount] = useState(0);
  const [documentId, setDocumentId] = useState(0);
  const [documentFileName, setDocumentFileName] = useState(null);
  const documentsNoLicenseNurse = [
    { document_name: "Passport", id: "F1", order: 1 },
    { document_name: "Birth Certificate", id: "F7", order: 2 },
    {
      document_name: "Marriage Certificate (If Applicable)",
      id: "F8",
      order: 3,
    },
    { document_name: "Higher Secondary (+2) Certificate", id: "F2", order: 4 },
    { document_name: "Mark List", id: "F26", order: 5 },
    { document_name: "Transcript", id: "F4", order: 6 },
    { document_name: "Degree Certificate", id: "F3", order: 7 },
    { document_name: "State Nursing Council Registration", id: "F5", order: 8 },
    {
      document_name: "Experience Certificates (Not Applicable Freshers)",
      id: "F6",
      order: 9,
    },
    {
      document_name:
        "Application Form For Your Nursing Recognition In Germany ",
      id: "F9",
      order: 10,
    },
    {
      document_name: "Power Of Attorney For Handling Your Documents ",
      id: "F10",
      order: 11,
    },
    { document_name: "Vaccination Document", id: "F11", order: 12 },
  ];
  const documentsLicenseNurse = [
    { document_name: "Passport", id: "F12", order: 1 },
    {
      document_name: "Urkunde - Nursing Registration Certificate In Germany",
      id: "F13",
      order: 2,
    },
    {
      document_name: "Bescheid - Deficit Notice Certificate In Germany",
      id: "F14",
      order: 3,
    },
    { document_name: "B2 Certificate", id: "F28", order: 4 },
    { document_name: "Experience Certificate", id: "F29", order: 5 },
    { document_name: "Visa Copy", id: "F30", order: 6 },
    { document_name: "3 Months Salary Slip", id: "F15", order: 7 },
  ];
  const documentsStudents = [
    { document_name: "Passport", id: "F16", order: 1 },
    { document_name: "Birth certificate", id: "F17", order: 2 },
    {
      document_name: "Secondary (10th) Certificate & Mark list",
      id: "F18",
      order: 3,
    },
    {
      document_name: "Higher Secondary (+2) Certificate & Mark list",
      id: "F19",
      order: 4,
    },
    {
      document_name: "Degree Certificate (If Applicable)",
      id: "F27",
      order: 5,
    },
    {
      document_name: "Marriage Certificate (If Applicable)",
      id: "F24",
      order: 6,
    },
    { document_name: "Motivation Letter (Word Format)", id: "F21", order: 7 },
    { document_name: "Power Of Attorney", id: "F22", order: 8 },
    {
      document_name:
        "ANTRAG AUF ANERKENNUNG/BEWERTUNG VON BILDUNGSNACHWEISEN (+2 Registration Application Form) ",
      id: "F23",
      order: 9,
    },
    { document_name: "Vaccination Document", id: "F25", order: 10 },
  ];
  const [documentsCount, setDocumentsCount] = useState(0);
  const [candidatesDocumentsCount, setCandidatesDocumentsCount] = useState(0);
  const [step, setStep] = useState("1");
  const [currentDocuments, setCurrentDocuments] = useState([]);

  const NONE = "NONE";
  const GNM = "GNM";
  const NURSE = "NURSE";
  const REGISTERED_NURSE = "REGISTERED_NURSE";

  useEffect(() => {
    // Log the state values after they have been updated
    console.log("Documents Count:", documentsCount);
    console.log("Candidates Documents Count:", candidatesDocumentsCount);
    console.log("Document Status:", dStatus);
  }, [documentsCount, candidatesDocumentsCount, dStatus]);

  useEffect(() => {
    _loadProfile();

    // Optional: Add script for external libraries
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function (if needed)
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (step === "4") {
      window.location.href = "/documents/verify";
    }
  }, [step]);

  const _loadProfile = async () => {
    setLoading(true);
    var profile;
    try {
      const response = await get("candidates/profile");
      //console.log(response.data.data);
      setMyProfile(response.data.data);
      profile = response.data.data;
      setStep(response.data.data.candidate_step);
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await post("candidates/documents");
      //console.log(profile)
      console.log(response.data);
      let docs;
      if (profile?.candidate_degree === "STUDENT") {
        docs = documentsStudents.filter(
          (doc) =>
            profile?.candidate_family_status?.includes("m") ||
            !doc.document_name.includes("Marriage Certificate")
        );
        setCandidatesDocumentsCount(docs.length);
      } else if (
        profile?.candidate_licence === "N" ||
        !profile?.candidate_licence
      ) {
        docs = documentsNoLicenseNurse.filter(
          (doc) =>
            profile?.candidate_family_status?.includes("m") ||
            !doc.document_name.includes("Marriage Certificate")
        );
        setCandidatesDocumentsCount(docs.length);
      } else {
        docs = documentsLicenseNurse;
        setCandidatesDocumentsCount(docs.length);
      }
      const updatedDocs = docs.map((doc) => {
        if (doc.doc_status == "1" || doc.doc_status == 1) {
          var rw = document.getElementById("reject-warn");
          if (rw != null) {
            rw.style.setProperty("display", "block", "important");
          }
        }
        const docFromServer = response.data.data.find((d) => d.id === doc.id);
        return {
          ...doc,
          status: docFromServer ? docFromServer.document_status : -1,
          fileName: docFromServer ? docFromServer.document_file_name : null,
          reason: docFromServer ? docFromServer.document_action_reason : "",
        };
      });
      console.log(updatedDocs);
      setCurrentDocuments(updatedDocs);
      setDocumentsCount(response.data.data.length);
      setDStatus(parseInt(response.data.document_status));
      if (response.data.document_amount !== undefined) {
        setProposalAmount(response.data.document_amount);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getData = () => {
    try {
      const value = Cookies.get("hcUser");
      if (value !== undefined) {
        return value;
      }
    } catch (e) {
      console.log("Error reading value from cookie:", e);
    }
    return null;
  };

  const uploadFile = async (e, data, id, order) => {
    const uploadedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("name", data);
    formData.append("id", id);
    formData.append("order", order);

    const token = getData();

    if (!token) {
      console.error("Token not found");
      return;
    }

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      "HC-User-Token": `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "candidates/document-upload",
        formData,
        { headers }
      );
      _loadProfile(); // Refresh document list after upload
    } catch (error) {
      alert("Document upload failed, Please try again");
      console.error("Upload error:", error);
    }
  };

  const payNow = async () => {
    var amount = proposalAmount * 100; // Razorpay considers the amount in paise
    var options = {
      key: process.env.REACT_APP_razorpaytest_id,
      amount: amount,
      name: "",
      description: "",
      order_id: "",
      handler: function (response) {
        const values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          transactionid: response.razorpay_payment_id,
          transactionamount: amount,
        };
        post("payments/upgrade", values)
          .then((res) => {
            setDStatus(4);
            setStep("3");
            alert("Success");
          })
          .catch((e) => console.log(e));
      },
      prefill: {
        name: myProfile.candidate_first_name,
        email: myProfile.candidate_email,
        contact: myProfile.candidate_phone,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#528ff0",
      },
    };

    try {
      const orderResponse = await post("payments/order", { amount });
      options.order_id = orderResponse.data.id;
      options.amount = orderResponse.data.amount;
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (err) {
      console.log(err);
    }
  };

  const documentStatus = {
    0: "Pending",
    1: "Submitted",
    2: "Waiting Proposal",
    3: "Waiting Payment",
    4: "Translation Progress",
    5: "Translation Under Review",
    6: "Completed",
  };

  const download = () => {
    window.open(
      process.env.REACT_APP_BASE_URL +
        "documents/translated/download/" +
        documentId,
      "_blank"
    );
  };

  const _view = (d) => {
    if (d.fileName) {
      setDocumentFileName(d.fileName);
      window.$("#largeLeftModalExample").modal("toggle");
    }
  };

  return (
    <>
      <Header />
      <Loader loading={loading} />

      {/* Modal for document preview */}
      <div
        className="modal modal-right large fade"
        id="largeLeftModalExample"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Document Preview</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <embed
                src={
                  process.env.REACT_APP_BASE_URL +
                  "documents/preview/" +
                  documentFileName
                }
                type="application/pdf"
                width={"100%"}
                height={"700px"}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <main>
        <div className="container-fluid">
          <div className="row">
            <SideBar
              mandateOne={true}
              mandateTwo={true}
              shouldShowPaymentStep={
                myProfile.candidate_degree != GNM &&
                myProfile.candidate_degree != NURSE &&
                myProfile.candidate_degree != REGISTERED_NURSE
              }
              step={step}
              license={myProfile.candidate_licence}
            />
            <div className="col">
              <div className="page-title-container mb-3">
                <div className="row">
                  <div className="col mb-2">
                    <h1 className="mb-2 pb-0 display-4" id="title">
                      Documents [Upload <b>PDF</b> format Only]
                    </h1>
                    <div className="text-muted font-heading text-small">
                      Document Details..
                    </div>
                  </div>
                </div>
              </div>

              {/* Conditional rendering based on document status */}
              {dStatus === 3 &&
                myProfile.candidate_degree != GNM &&
                myProfile.candidate_degree != NURSE &&
                myProfile.candidate_degree != REGISTERED_NURSE && (
                  <div className="col-12 mt-1 mb-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="alert alert-success" role="alert">
                          <h4 className="alert-heading">
                            Your document translation payment is due -{" "}
                            {proposalAmount}!
                          </h4>
                          <p>
                            Please contact our support team to make the payment.
                            <br />
                            Phone: +49 1517 123 2244, Email: info@hireandcare.de
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {dStatus == 6 && myProfile.candidate_licence != "Y" && (
                <div className="row">
                  <div className="col-12 mt-1 mb-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="alert alert-info" role="alert">
                          <h4 className="alert-heading">
                            Successfully translated your document!
                          </h4>
                          <p></p>
                          <hr />
                          <p className="mb-0">
                            <button
                              type="button"
                              className="btn btn-primary mb-1"
                              onClick={() => download()}
                            >
                              Download Now
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {dStatus == 6 && myProfile.candidate_licence == "Y" && (
                <div className="row">
                  <div className="col-12 mt-1 mb-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="alert alert-info" role="alert">
                          <h4 className="alert-heading">
                            Successfully approved your documents!
                          </h4>
                          <p>Our executives will contact you..</p>

                          <p className="mb-0">
                            Please contact our support team if any queries.
                            <br />
                            Phone: +49 1517 123 2244, Email: info@hireandcare.de
                            {/*<button type="button" className="btn btn-primary mb-1" onClick={() => payNow()}>Pay Now</button>*/}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-2 mt-5 mb-5">
                <div className="col-12">
                  {myProfile.candidate_licence != "Y" && dStatus > 0 && (
                    <div className="col-12 hh-grayBox rem-pad">
                      <div className="row justify-content-between">
                        {dStatus >= 1 && (
                          <div className="order-tracking completed">
                            <span className="is-complete"></span>
                            <p>Apply</p>
                          </div>
                        )}

                        {dStatus >= 2 ? (
                          <div className="order-tracking completed">
                            <span className="is-complete"></span>
                            <p>
                              Waiting for
                              <br />
                              translator
                            </p>
                          </div>
                        ) : (
                          <div className="order-tracking completed hidden">
                            <span className="is-complete hiddens"></span>
                            <p>
                              Waiting for
                              <br />
                              translator
                            </p>
                          </div>
                        )}

                        {dStatus >= 4 ? (
                          <div className="order-tracking completed">
                            <span className="is-complete"></span>
                            <p>
                              Translation
                              <br />
                              progress
                            </p>
                          </div>
                        ) : (
                          <div className="order-tracking completed hidden">
                            <span className="is-complete hiddens"></span>
                            <p>
                              Translation
                              <br />
                              progress
                            </p>
                          </div>
                        )}

                        {dStatus >= 5 ? (
                          <div className="order-tracking completed">
                            <span className="is-complete"></span>
                            <p>
                              Under
                              <br />
                              review
                            </p>
                          </div>
                        ) : (
                          <div className="order-tracking completed hidden">
                            <span className="is-complete hiddens"></span>
                            <p>
                              Under
                              <br />
                              review
                            </p>
                          </div>
                        )}

                        {dStatus >= 6 ? (
                          <div className="order-tracking completed">
                            <span className="is-complete"></span>
                            <p>Completed</p>
                          </div>
                        ) : (
                          <div className="order-tracking hidden">
                            <span className="is-complete hiddens"></span>
                            <p>Completed</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {documentsCount === candidatesDocumentsCount && dStatus == 0 && (
                <>
                  <div className="alert alert-success" role="alert">
                    <i
                      data-acorn-icon="check-circle"
                      className="pin"
                      data-acorn-size="26"
                    ></i>{" "}
                    All documents have been submitted for verification
                  </div>
                </>
              )}

              <div
                id="reject-warn"
                className="alert alert-danger d-none"
                role="alert"
              >
                <i
                  data-acorn-icon="shield-warning"
                  className="pin"
                  data-acorn-size="26"
                ></i>{" "}
                One or more documents rejected,please re upload documents
              </div>

              <div className="row mb-2">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  {/* Document List */}
                  <div className="card mb-5">
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Document</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentDocuments.map((d, k) => (
                            <tr key={d.id}>
                              <th scope="row">{k + 1}</th>
                              <td>
                                {d.document_name}
                                {d.id == "F22" && (
                                  <a
                                    target="_blank"
                                    href="https://hireandcare.de/documents/students/PowerofAttorneyausbildung.docx"
                                  >
                                    Click Here to Download
                                  </a>
                                )}

                                {d.id == "F23" && (
                                  <a
                                    target="_blank"
                                    href="https://hireandcare.de/documents/students/Antrag_Anerkennung_Bildungsnachweise.pdf"
                                  >
                                    Click Here to Download
                                  </a>
                                )}

                                {d.id == "F10" && (
                                  <a
                                    target="_blank"
                                    href="https://hireandcare.de/documents/nurse/PowerofAttorney-Nurse.docx"
                                  >
                                    Click Here to Download
                                  </a>
                                )}

                                {d.id == "F9" && (
                                  <a
                                    target="_blank"
                                    href="https://hireandcare.de/documents/nurse/LPA_BerBez-Ausl-KiKrPf-nurse.pdf"
                                  >
                                    Click Here to Download
                                  </a>
                                )}
                              </td>
                              <td>
                                {d.status === -1 ? (
                                  "Pending"
                                ) : d.status === 1 ? (
                                  <span
                                    className="badge bg-danger text-uppercase"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={d.reason}
                                  >
                                    Rejected
                                  </span>
                                ) : d.status !== 6 ? (
                                  "Uploaded"
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {d.status === -1 || d.status === 1 ? (
                                  <>
                                    <input
                                      id={"f_" + d.id}
                                      accept={
                                        d.document_name
                                          .toLowerCase()
                                          .includes("motivation letter") ||
                                        d.document_name
                                          .toLowerCase()
                                          .includes("power of attorney") ||
                                        d.document_name
                                          .toLowerCase()
                                          .includes("antrag")
                                          ? ".pdf,.doc,.docx"
                                          : "application/pdf"
                                      }
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        uploadFile(
                                          e,
                                          d.document_name,
                                          d.id,
                                          d.order
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"f_" + d.id}
                                      className="badge bg-outline-primary group"
                                    >
                                      UPLOAD
                                    </label>
                                  </>
                                ) : (
                                  <button
                                    className="badge bg-outline-primary group"
                                    onClick={() => _view(d)}
                                  >
                                    VIEW
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*CARD END*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Documents;
