import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import avatar from "../../images/avatar.jpg";
import axios from "axios";
import Parser from "html-react-parser";

const Enquiries = () => {
  const [loading, setLoading] = useState(false);
  const [enquiry, setEnquiry] = useState([]);
  const [step, setStep] = useState("0");
  const [license, setLicense] = useState("Y");

  const [myProfile, setMyProfile] = useState({
      candidate_education_details: {},
      candidate_nurse_course: {},
      candidate_language_course: {},
    });

  const NONE = "NONE";
  const GNM = "GNM";
  const NURSE = "NURSE";
  const REGISTERED_NURSE = "REGISTERED_NURSE";

  useEffect(() => {
    _loadProfile();
    _loadEnquiries();
  }, []);

  const _loadEnquiries = () => {
    setLoading(true);
    get("candidates/enquiries")
      .then(async function (response) {
        setEnquiry(response.data.data);
        setStep(response.data.step);
        setLicense(response.data.license);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const _loadProfile = () => {
      setLoading(true);
      get("candidates/profile")
        .then(async function (response) {
          console.log("profile: " + JSON.stringify(response.data.data));
          setMyProfile(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

  return (
    <>
      <Header />
      <Loader loading={loading} />

      <main>
        <div className="container-fluid">
          <div className="row">
            <SideBar
              mandateOne={true}
              mandateTwo={true}
              step={step}
              shouldShowPaymentStep={
                myProfile.candidate_degree != GNM &&
                myProfile.candidate_degree != NURSE &&
                myProfile.candidate_degree != REGISTERED_NURSE
              }
              license={license}
            />
            <div className="col">
              <div className="page-title-container mb-3">
                <div className="row">
                  <div className="col mb-2">
                    <h1 className="mb-2 pb-0 display-4" id="title">
                      Enquiry Details
                    </h1>
                  </div>
                </div>
              </div>

              <div className="row">
                {enquiry.map((e, k) => (
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="text-capitalize">
                          {e.employer_details.employer_name}
                        </h3>
                        <span className="text-muted">
                          {e.employer_details.employer_agency}
                        </span>
                        <hr />
                        <div class="d-flex justify-content-between">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="acorn-icons acorn-icons-calendar mb-3 d-inline-block text-primary"
                          >
                            <path d="M14.5 4C15.9045 4 16.6067 4 17.1111 4.33706C17.3295 4.48298 17.517 4.67048 17.6629 4.88886C18 5.39331 18 6.09554 18 7.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111C17.517 17.3295 17.3295 17.517 17.1111 17.6629C16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629C2.67048 17.517 2.48298 17.3295 2.33706 17.1111C2 16.6067 2 15.9045 2 14.5L2 7.5C2 6.09554 2 5.39331 2.33706 4.88886C2.48298 4.67048 2.67048 4.48298 2.88886 4.33706C3.39331 4 4.09554 4 5.5 4L14.5 4Z"></path>
                            <path d="M2 9H18M7 2 7 5.5M13 2 13 5.5M5 15H6"></path>
                          </svg>
                          <div>{e.interview_date}</div>
                        </div>

                        <div class="d-flex justify-content-between">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="acorn-icons acorn-icons-clock mb-3 d-inline-block text-primary"
                          >
                            <path d="M8 12L9.70711 10.2929C9.89464 10.1054 10 9.851 10 9.58579V6"></path>
                            <circle cx="10" cy="10" r="8"></circle>
                          </svg>
                          <div>{e.interview_time}</div>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="acorn-icons acorn-icons-web mb-3 d-inline-block text-primary"
                          >
                            <circle cx="10" cy="10" r="8"></circle>
                            <path d="M7.5 17.5C6.49999 12 6.5 8 7.50001 2.5M12 17.5C13 12 13 8 12 2.5"></path>
                            <path d="M17 13.5C11.5 12.5 8.5 12.5 3 13.5M3 6.5C8.5 7.50001 11.5 7.5 17 6.5"></path>
                          </svg>
                          <div>{e.interview_url}</div>
                        </div>

                        {/*<div class="d-flex justify-content-between mt-5">

                                                <button class="btn btn-icon btn-icon-only btn-success mb-1" type="button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-like undefined"><path d="M7 16V9.67097C7 9.23919 7.13974 8.819 7.39832 8.47321L7.83416 7.8904C8.26979 7.30784 8.53833 6.61753 8.61086 5.89373L8.83055 3.70144C8.92826 2.72635 9.76756 1.85793 10.6682 2.24409 11.8877 2.76691 12.4524 4.15875 11.9236 6.69693 11.7865 7.35486 12.2627 8.00651 12.9348 8.00651L15.8519 8.00651C17.0126 8.00651 17.9296 8.99136 17.8468 10.1491L17.6805 12.4748C17.5611 14.1456 17.1521 15.7828 16.4718 17.3135 16.2862 17.7309 15.8722 18 15.4154 18H9C7.89543 18 7 17.1046 7 16zM4.5 9C4.96466 9 5.19698 9 5.39018 9.03843 6.18356 9.19624 6.80376 9.81644 6.96157 10.6098 7 10.803 7 11.0353 7 11.5L7 15.5C7 15.9647 7 16.197 6.96157 16.3902 6.80376 17.1836 6.18356 17.8038 5.39018 17.9616 5.19698 18 4.96466 18 4.5 18V18C4.03534 18 3.80302 18 3.60982 17.9616 2.81644 17.8038 2.19624 17.1836 2.03843 16.3902 2 16.197 2 15.9647 2 15.5L2 11.5C2 11.0353 2 10.803 2.03843 10.6098 2.19624 9.81644 2.81644 9.19624 3.60982 9.03843 3.80302 9 4.03535 9 4.5 9V9z"></path></svg>
                                                </button>

                                                <button class="btn btn-icon btn-icon-only btn-quaternary mb-1" type="button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-mic undefined"><path d="M7 5C7 3.34315 8.34315 2 10 2V2C11.6569 2 13 3.34315 13 5V9C13 10.6569 11.6569 12 10 12V12C8.34315 12 7 10.6569 7 9V5Z"></path><path d="M4 8.5V9C4 12.3137 6.68629 15 10 15V15C13.3137 15 16 12.3137 16 9V8.5M10 15V16 18M8 18H12M10 5V6"></path></svg>
                                                </button>

                                                <button class="btn btn-icon btn-icon-only btn-light mb-1" type="button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-unlike undefined"><path d="M13 4.00003V10.3291C13 10.7608 12.8603 11.181 12.6017 11.5268L12.1658 12.1096C11.7302 12.6922 11.4617 13.3825 11.3891 14.1063L11.1695 16.2986C11.0717 17.2737 10.2324 18.1421 9.33175 17.7559 8.11231 17.2331 7.54758 15.8413 8.07641 13.3031 8.21349 12.6452 7.73728 11.9935 7.06521 11.9935L4.1481 11.9935C2.98736 11.9935 2.07041 11.0087 2.15319 9.85089L2.31946 7.52518C2.43891 5.85445 2.84791 4.2172 3.5282 2.68657 3.71375 2.26908 4.12777 2.00003 4.58464 2.00003H11C12.1046 2.00003 13 2.89546 13 4.00003zM15.5 11C15.0353 11 14.803 11 14.6098 10.9616 13.8164 10.8038 13.1962 10.1836 13.0384 9.39018 13 9.19698 13 8.96466 13 8.5L13 4.5C13 4.03534 13 3.80302 13.0384 3.60982 13.1962 2.81644 13.8164 2.19624 14.6098 2.03843 14.803 2 15.0353 2 15.5 2V2C15.9647 2 16.197 2 16.3902 2.03843 17.1836 2.19624 17.8038 2.81644 17.9616 3.60982 18 3.80302 18 4.03535 18 4.5L18 8.5C18 8.96466 18 9.19698 17.9616 9.39018 17.8038 10.1836 17.1836 10.8038 16.3902 10.9616 16.197 11 15.9647 11 15.5 11V11z"></path></svg>
                                                </button>
    </div>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Enquiries;
