import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import avatar from "../../images/avatar.jpg";
import axios from "axios";
import Parser from "html-react-parser";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState("0%");
  const [saveBtn, setSaveBtn] = useState(false);
  const [myProfile, setMyProfile] = useState({
    candidate_education_details: {},
    candidate_nurse_course: {},
    candidate_language_course: {},
  });
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [speaking, setSpeaking] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [languageSkills, setLanguageSkills] = useState([]);
  const [candidateStatus, setCandidateStatus] = useState(0);
  const [step, setStep] = useState("0");
  const [candidateId, setCandidateId] = useState(null);
  const [image, setImage] = useState(null);
  const [candidateResume, setCandidateResume] = useState(null);
  const [schoolsDiv, setSchoolsDiv] = useState(null);
  const [schoolsArr, setSchoolsArr] = useState([]);
  const [candidateSchools, setCandidateSchools] = useState([]);
  const [candidateCollege, setCandidateCollege] = useState([]);

  const [courseDiv, setCourseDiv] = useState(null);
  const [courseArr, setCourseArr] = useState([]);

  const [languageCourseDiv, setLanguageCourseDiv] = useState(null);
  const [languageCourseArr, setLanguageCourseArr] = useState([]);

  const [schoolsLowerArr, setSchoolsLowerArr] = useState([]);
  const [schoolsUpperArr, setSchoolsUpperArr] = useState([]);
  const [schoolsHighArr, setSchoolsHighArr] = useState([]);

  const [departments, setDepartments] = useState([]);

  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  const NONE = "NONE";
  const GNM = "GNM";
  const NURSE = "NURSE";
  const REGISTERED_NURSE = "REGISTERED_NURSE";

  useEffect(() => {
    _loadCountry();
    _loadNationality();
    _loadProfile();
    _loadSpeaking();
    _loadHobbies();
    _loadDesignations();
    _loadDepartments();

    setTimeout(function () {
      window.$(function () {
        const d = new Date();
        let year = d.getFullYear();

        window.$(".date-picker").datepicker({
          dateFormat: "MM yy",
          changeMonth: true,
          changeYear: true,
          showButtonPanel: true,
          maxDate: "+28D",
          yearRange: "1970:" + year,

          onClose: function (dateText, inst) {
            function isDonePressed() {
              return (
                window
                  .$("#ui-datepicker-div")
                  .html()
                  .indexOf(
                    "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover"
                  ) > -1
              );
            }
            if (isDonePressed()) {
              var month = window
                .$("#ui-datepicker-div .ui-datepicker-month :selected")
                .val();
              var year = window
                .$("#ui-datepicker-div .ui-datepicker-year :selected")
                .val();
              window
                .$(this)
                .val(
                  window.$.datepicker.formatDate(
                    "MM yy",
                    new Date(year, month, 1)
                  )
                );
            }
          },
        });

        window.$(".date-picker").focus(function () {
          window.$(".ui-datepicker-calendar").hide();
          window.$("#ui-datepicker-div").position({
            my: "center top",
            at: "center bottom",
            of: window.$(this),
          });
        });
      });
    }, 4000);
  }, []);

  useEffect(() => {
    /*if (step == "1" || step == "2" || step == "3" ) {
            window.location.href = "/documents";
        }
        else if (step == "4" || step == "5") {
            window.location.href = "/documents/verify";
        }*/
  }, [step]);

  const _loadProfile = () => {
    setLoading(true);
    get("candidates/profile")
      .then(async function (response) {
        console.log("profile: " + JSON.stringify(response.data.data));
        setMyProfile(response.data.data);
        setCandidateStatus(response.data.data.candidate_status);
        setStep(response.data.data.candidate_step);
        setCandidateId(response.data.data.candidate_id);
        setCandidateResume(response.data.data.candidate_resume);
        setCandidateSchools(response.data.data.candidate_schools);
        //setCandidateCollege

        if (response.data.data.candidate_colleges != undefined)
          setCandidateCollege(response.data.data.candidate_colleges);

        if (response.data.data.candidate_image != null)
          setImage(
            process.env.REACT_APP_BASE_URL +
              "candidates/images/" +
              response.data.data.candidate_image
          );

        setTimeout(function () {
          if (response.data.data.candidate_additional_note != undefined) {
            if (document.getElementById("additional_note") != null)
              document.getElementById("additional_note").value =
                response.data.data.candidate_additional_note;
          }

          if (response.data.data.candidate_residency != undefined) {
            if (document.getElementById("country") != null)
              document.getElementById("country").value =
                response.data.data.candidate_residency;
          }

          if (response.data.data.candidate_nationality != undefined) {
            document.getElementById("nationality").value =
              response.data.data.candidate_nationality;
          }

          //if (response.data.data.candidate_degree != 'STUDENT') {
          if (response.data.data.candidate_experience != undefined) {
            document.getElementById("nc_total_experience").value =
              response.data.data.candidate_experience;
          }
          //}

          if (
            response.data.data.candidate_current_language_level != undefined
          ) {
            document.getElementById("change_language_level").value =
              response.data.data.candidate_current_language_level;
          }

          if (response.data.data.candidate_gender != undefined)
            document.getElementById("gender").value =
              response.data.data.candidate_gender;

          if (
            response.data.data.candidate_language_course.lc_language_level !=
            undefined
          ) {
            document.getElementById("lc_language_level").value =
              response.data.data.candidate_language_course.lc_language_level;
          }

          if (
            response.data.data.candidate_language_course.country != undefined
          ) {
            document.getElementById("language_course_country").value =
              response.data.data.candidate_language_course.country;
          }

          if (response.data.data.candidate_family_status != undefined)
            document.getElementById("family_status").value =
              response.data.data.candidate_family_status;

          if (response.data.data.candidate_birth_place != undefined)
            document.getElementById("birth_place").value =
              response.data.data.candidate_birth_place;

          if (response.data.data.candidate_education_details != undefined)
            if (
              response.data.data.candidate_education_details.country !=
              undefined
            ) {
              document.getElementById("hs_country").value =
                response.data.data.candidate_education_details.country;
            }

          if (response.data.data.candidate_nurse_course != undefined) {
            if (response.data.data.candidate_nurse_course.course != undefined) {
              document.getElementById("nc_course").value =
                response.data.data.candidate_nurse_course.course;
            }

            if (
              response.data.data.candidate_nurse_course.country != undefined
            ) {
              document.getElementById("nc_country").value =
                response.data.data.candidate_nurse_course.country;

              if (response.data.data.candidate_nurse_course.country) {
                setPercentage("20%");
              }
            }
          }

          if (response.data.data.candidate_professional != undefined) {
            //if (response.data.data.candidate_degree != 'STUDENT') {
            for (
              var j = 0;
              j < response.data.data.candidate_professional.length;
              j++
            ) {
              document.getElementById("experience_start_" + j).value =
                response.data.data.candidate_professional[j].experience_start;
              document.getElementById("experience_end_" + j).value =
                response.data.data.candidate_professional[j].experience_end;
              document.getElementById("experience_designation_" + j).value =
                response.data.data.candidate_professional[j].designation_id;
              document.getElementById("experience_employer_" + j).value =
                response.data.data.candidate_professional[j].employer_name;
              //document.getElementById("experience_bed_capacity_" + j).value = response.data.data.candidate_professional[j].bed_capacity;

              document.getElementById("experience_country_" + j).value =
                response.data.data.candidate_professional[j].country;

              if (response.data.data.candidate_professional[j].till_now != "") {
                document.getElementById(
                  "experience_till_now_" + j
                ).checked = true;
              }

              var optionsToSelect =
                response.data.data.candidate_professional[j].bed_capacity;
              var select = document.getElementById(
                "experience_bed_capacity_" + j
              );

              for (var i = 0, l = select.options.length, o; i < l; i++) {
                o = select.options[i];
                if (optionsToSelect.indexOf(o.value) != -1) {
                  o.selected = true;
                }
              }
            }
            //}

            if (response.data.data.candidate_professional.length > 0) {
              setPercentage("60%");
            }
          }

          if (response.data.data.candidate_language_level != undefined) {
            var t = [];
            for (
              var j = 0;
              j < response.data.data.candidate_language_level.length;
              j++
            ) {
              var tmp = {
                name: response.data.data.candidate_language_level[j]
                  .speaking_name,
                language:
                  response.data.data.candidate_language_level[j].language,
                level:
                  response.data.data.candidate_language_level[j]
                    .candidate_language_level,
              };

              //console.log("---tmp---", tmp);
              t.push(tmp);
              //setLanguageSkills([...languageSkills, tmp]);
            }

            setLanguageSkills(t);

            if (response.data.data.candidate_language_level.length > 0) {
              setPercentage("100%");
            }
          }

          if (response.data.data.candidate_hobbies != undefined) {
            for (
              var j = 0;
              j < response.data.data.candidate_hobbies.length;
              j++
            ) {
              if (
                document.getElementById(
                  "hb_" + response.data.data.candidate_hobbies[j].hobby_id
                ) != undefined
              )
                document.getElementById(
                  "hb_" + response.data.data.candidate_hobbies[j].hobby_id
                ).checked = true;
            }

            if (response.data.data.candidate_hobbies.length > 0) {
              setPercentage("100%");
            }
          }

          if (response.data.data.candidate_degree == "STUDENT") {
            var schools = "";

            if (document.getElementById("schools") != null) {
              schools = document.getElementById("schools").innerHTML;
            }

            setSchoolsDiv(schools);
            document.getElementById("schools").innerHTML = "";
          }

          setLoading(false);

          if (!response.data.data.has_read_information_brochure_2024) {
            setShowBrochureModal(true);
          }

          //alert(response.data.data.candidate_degree)

          window.$(".departments").select2({
            placeholder: "Select Departments",
          });
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadCountry = () => {
    get("countries/en")
      .then(async function (response) {
        setCountries(response.data.data);
        setTimeout(function () {
          var nursingCourse =
            document.getElementById("nursingCourse").innerHTML;
          setCourseDiv(nursingCourse);
          document.getElementById("nursingCourse").innerHTML = "<div></div>";

          var languageCourse =
            document.getElementById("language_course").innerHTML;
          setLanguageCourseDiv(languageCourse);
          document.getElementById("language_course").innerHTML = "<div></div>";
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadNationality = () => {
    get("nationality/en")
      .then(async function (response) {
        setNationality(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadSpeaking = () => {
    get("speaking/en")
      .then(async function (response) {
        setSpeaking(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadHobbies = () => {
    get("hobbies/en")
      .then(async function (response) {
        setHobbies(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadDesignations = () => {
    get("destinations/en")
      .then(async function (response) {
        setDesignations(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _loadDepartments = () => {
    get("departments/en")
      .then(async function (response) {
        setDepartments(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _addLanguageSkills = () => {
    var language = document.getElementById("language");
    var language_level = document.getElementById("language_level").value;
    //document.getElementById('language_level').value;

    if (language.value) {
      var exists = false;
      for (var i = 0; i < languageSkills.length; i++) {
        if (
          languageSkills[i].language == language.value &&
          languageSkills[i].level == language_level
        ) {
          exists = true;
        }
      }

      var tmp = {
        name: language.options[language.selectedIndex].text,
        language: language.value,
        level: language_level,
      };

      if (exists == false) setLanguageSkills([...languageSkills, tmp]);
    }
  };

  const enableEdit = (e) => {
    setSaveBtn(true);
  };

  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  function multipleInArray(arr, values) {
    return values.every((value) => {
      return arr.includes(value);
    });
  }

  const save = (e) => {
    removeErrors();
    var error = false;

    var elements = document.getElementsByClassName("required");
    for (var i = 0; i < elements.length; i++) {
      var error_message = elements[i].getAttribute("data-error");

      if (elements[i].value == "") {
        createError(elements[i], error_message);
        error = true;
      }
    }

    var courseData = [];
    var school_details = "";
    var course_details = "";
    var experienceData = [];
    var collegeData = [];

    if (myProfile.candidate_degree == "STUDENT") {
      var school_start = document.getElementsByName("school_start[]");
      var school_end = document.getElementsByName("school_end[]");
      var class_from = document.getElementsByName("class_from[]");
      var class_to = document.getElementsByName("class_to[]");
      var school = document.getElementsByName("school[]");
      var school_country = document.getElementsByName("school_country[]");

      var schoolData = [];
      var collegeData = [];

      for (var j = 0; j < school_start.length; j++) {
        if (school_start[j].value) {
          schoolData.push({
            school_start: school_start[j].value,
            school_end: school_end[j].value,

            class_from: class_from[j].value,
            class_to: class_to[j].value,

            school: school[j].value,
            country: school_country[j].value,
          });
        }
      }

      var college_start = document.getElementsByName("college_start[]");
      var college_end = document.getElementsByName("college_end[]");
      var college_school = document.getElementsByName("college_school[]");
      var college_course = document.getElementsByName("college_course[]");
      var college_state = document.getElementsByName("college_state[]");
      var college_country = document.getElementsByName("college_country[]");

      for (var j = 0; j < college_start.length; j++) {
        if (college_start[j].value && college_school[j] != undefined) {
          collegeData.push({
            college_start: college_start[j].value,
            college_end: college_end[j].value,

            college_school: college_school[j].value,
            college_course: college_course[j].value,

            college_state: college_state[j].value,
            country: college_country[j].value,
          });
        }
      }
    } else {
      var nc_start = document.getElementsByName("nc_start[]");
      var nc_end = document.getElementsByName("nc_end[]");
      var nc_course = document.getElementsByName("nc_course[]");
      var nc_college = document.getElementsByName("nc_college[]");
      var nc_university = document.getElementsByName("nc_university[]");
      var nc_state = document.getElementsByName("nc_state[]");
      var nc_country = document.getElementsByName("nc_country[]");
      var tmp = [];

      for (var j = 0; j < nc_start.length; j++) {
        for (var k = 0; k < tmp.length; k++) {
          if (tmp[k] == nc_course[j].value) {
            alert("Nursing courses are often selected repeatedly");
            error = true;
            return false;
          }
        }

        tmp.push(nc_course[j].value);

        if (nc_start[j].value) {
          courseData.push({
            nc_start: nc_start[j].value,
            nc_end: nc_end[j].value,

            nc_course: nc_course[j].value,
            nc_college: nc_college[j].value,

            nc_university: nc_university[j].value,
            nc_state: nc_state[j].value,

            nc_country: nc_country[j].value,
          });
        }
      }
    }

    if (image == null) {
      alert("Please upload profile image");
      error = true;
    }

    if (error == false) {
      var first_name = document.getElementById("first_name").value;
      var last_name = document.getElementById("last_name").value;
      var phone = document.getElementById("phone").value;
      var email = document.getElementById("email").value;
      var gender = document.getElementById("gender").value;
      var family_status = document.getElementById("family_status").value;
      var country = document.getElementById("country").value;
      var nationality = document.getElementById("nationality").value;
      //var passport_number = document.getElementById('passport_number').value;
      //var passport_expiry_date = document.getElementById('passport_expiry_date').value;
      var present_address = document.getElementById("present_address").value;
      var present_address_passport = document.getElementById(
        "present_address_passport"
      ).value;
      var dob = document.getElementById("dob").value;
      var birth_place = document.getElementById("birth_place").value;

      var lc_language_level =
        document.getElementById("lc_language_level").value;
      var lc_start = document.getElementById("language_course_start").value;
      var lc_end = document.getElementById("language_course_end").value;
      var lc_school = document.getElementById("language_course_school").value;
      var lc_state = document.getElementById("language_course_state").value;
      var lc_country = document.getElementById("language_course_country").value;
      var lc_till_now = "";

      if (document.getElementById("language_course_till_now").checked) {
        if (myProfile.candidate_degree == "NURSE") {
          lc_till_now = "bis jetzt";
          lc_end = "March 2024";
        } else if (myProfile.candidate_degree == "REGISTERED_NURSE") {
          lc_till_now = "Ab";
          lc_end = "March 2024";
        }
      }

      /*EDUCATIONAL DETAILS HIGHER SECONDARY*/
      var hs_start = document.getElementById("hs_start").value;
      var hs_end = document.getElementById("hs_end").value;
      var hs_school = document.getElementById("hs_school").value;
      var hs_university = document.getElementById("hs_universiry").value;
      var hs_state = document.getElementById("hs_state").value;
      var hs_country = document.getElementById("hs_country").value;

      /*EDUCATIONAL DETAILS NURSING*/
      if (myProfile.candidate_degree != "STUDENT") {
        var nc_start = document.getElementById("nc_start").value;
        var nc_end = document.getElementById("nc_end").value;
        var nc_course = document.getElementById("nc_course").value;
        var nc_college = document.getElementById("nc_college").value;
        var nc_university = document.getElementById("nc_universiry").value;
        var nc_state = document.getElementById("nc_state").value;
        var nc_country = document.getElementById("nc_country").value;
      } else {
        var nc_total_experience,
          nc_start,
          nc_end,
          nc_course,
          nc_college,
          nc_university,
          nc_state,
          nc_country,
          experience_bed_capacity,
          experience_employer,
          experience_designation,
          experience_start,
          experience_end = "";
      }

      for (var i = 0; i < 7; i++) {
        var till_now = "";

        if (document.getElementById("experience_till_now_" + i).checked) {
          if (myProfile.candidate_degree == "NURSE") {
            till_now = "bis jetzt";
            nc_end = "";
          } else if (myProfile.candidate_degree == "REGISTERED_NURSE") {
            till_now = "Ab";
            nc_end = "";
          }
        }

        var departments = document.getElementById(
          "experience_bed_capacity_" + i
        ).selectedOptions;
        var values = Array.from(departments).map(({ value }) => value);

        var ed = {
          experience_start: document.getElementById("experience_start_" + i)
            .value,
          experience_end: document.getElementById("experience_end_" + i).value,
          experience_designation: document.getElementById(
            "experience_designation_" + i
          ).value,
          experience_employer: document.getElementById(
            "experience_employer_" + i
          ).value,
          experience_bed_capacity: values,
          experience_till_now: till_now,
          experience_country: document.getElementById("experience_country_" + i)
            .value,
        };

        if (document.getElementById("experience_start_" + i).value != "") {
          experienceData.push(ed);
        }
      }
      var nc_total_experience = document.getElementById(
        "nc_total_experience"
      ).value;

      var experience_start =
        document.getElementById("experience_start_0").value;
      var experience_end = document.getElementById("experience_end_0").value;
      var experience_designation = document.getElementById(
        "experience_designation_0"
      ).value;
      var experience_employer = document.getElementById(
        "experience_employer_0"
      ).value;
      var experience_bed_capacity = document.getElementById(
        "experience_bed_capacity_0"
      ).value;

      var languageSkillsCandidate = JSON.stringify(languageSkills);
      var hobbiesElements = document.getElementsByClassName("hobbies");
      var hobbies = [];

      for (var j = 0; j < hobbiesElements.length; j++) {
        if (hobbiesElements[j].checked) {
          hobbies.push(hobbiesElements[j].value);
        }
      }

      var candidateHobbies = JSON.stringify(hobbies);
      var additionalNote = document.getElementById("additional_note").value;
      var mother_tongue = document.getElementById("mother_tongue").value;

      var raw = JSON.stringify({
        candidate_residency: country,
        candidate_nationality: nationality,
        candidate_gender: gender,
        candidate_family_status: family_status,
        candidate_passport_details: "",
        candidate_passport_expiry_date: "",
        candidate_address: present_address,
        candidate_passport_address: present_address_passport,
        candidate_last_name: last_name,
        candidate_first_name: first_name,
        candidate_dob: dob,
        candidate_hs_start: hs_start,
        candidate_hs_end: hs_end,
        candidate_hs_school: hs_school,
        candidate_hs_university: hs_university,
        candidate_hs_state: hs_state,
        candidate_hs_country: hs_country,
        candidate_nc_start: nc_start,
        candidate_nc_end: nc_end,
        candidate_nc_course: nc_course,
        candidate_nc_college: nc_college,
        candidate_nc_university: nc_university,
        candidate_nc_state: nc_state,
        candidate_nc_country: nc_country,
        candidate_languageSkills: languageSkills,
        candidate_hobbies: candidateHobbies,
        candidate_experience_total: nc_total_experience,
        candidate_experience_start: experience_start,
        candidate_experience_end: experience_end,
        candidate_experience_designation: experience_designation,
        candidate_experience_employer: experience_employer,
        candidate_experience_bed_capacity: experience_bed_capacity,
        candidate_school_details: JSON.stringify(schoolData),
        candidate_college_details: JSON.stringify(collegeData),
        candidate_nc_course_details: JSON.stringify(courseData),
        candidate_experince: JSON.stringify(experienceData),
        candidate_additional_note: additionalNote,
        lc_language_level: lc_language_level,
        lc_start: lc_start,
        lc_end: lc_end,
        lc_school: lc_school,
        lc_state: lc_state,
        lc_country: lc_country,
        lc_till_now: lc_till_now,
        mother_tongue: mother_tongue,
        candidate_birth_place: birth_place,
      });

      setLoading(true);
      put("candidates", raw)
        .then(async function (response) {
          setLoading(false);
          setSaveBtn(false);
          _loadProfile();

          alert("Successfully updated profile details");

          if (candidateStatus == 1) {
            setStep("1");
            window.location.href = "/documents";
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const experiences = () => {
    var experiences = [];

    for (var i = 1; i <= 20; i++) {
      experiences.push(<option value={i}>{i + " Years"}</option>);
    }

    return experiences;
  };

  const experienceDetails = () => {
    var data = [];
    var required = "";
    for (var j = 0; j < 7; j++) {
      data.push(
        <div className="row mb-3">
          <div className="col-sm-2 form-group">
            <label className="mb-3 top-label">
              <input
                type="date"
                id={"experience_start_" + j}
                className={"form-control " + required}
                data-error="Enter start date"
              />
              <span style={{ color: "rgb(94, 92, 92)" }}>Start Date</span>
            </label>
          </div>

          <div className="col-sm-2 form-group">
            <label className="top-label">
              <input
                type="date"
                id={"experience_end_" + j}
                className={"form-control " + required}
                data-error="Enter end date"
              />
              <span style={{ color: "rgb(94, 92, 92)" }}>End Date</span>
            </label>
            <label>
              <input
                type="checkbox"
                name={"experience_till_now_" + j}
                id={"experience_till_now_" + j}
                value="1"
              />{" "}
              Till Now
            </label>
          </div>

          <div className="col-sm-8">
            <div
              style={{
                border: "1px solid #ced4da",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 10,
              }}
            >
              <label
                className="mb-1"
                style={{ color: "#1975ba", fontWeight: "500" }}
              >
                {/*<input type="text" id={"experience_bed_capacity_" + j} className={"form-control " + required} data-error="Enter bed capacity" />*/}
                <span style={{ color: "#1975ba", fontWeight: "500" }}>
                  Department
                </span>
              </label>
              <select
                multiple
                className={"departments form-control " + required}
                id={"experience_bed_capacity_" + j}
                data-error="Please select department"
              >
                {departments.map((d, k) => (
                  <option value={d.department_id}>{d.department_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm-4 form-group">
            <label className="mb-3 top-label">
              <select
                className={"form-control " + required}
                id={"experience_designation_" + j}
                data-error="Please select designation"
              >
                <option value={""}>Select</option>
                {designations.map((d, k) => (
                  <option value={d.destination_id}>{d.destination_name}</option>
                ))}
              </select>
              <span style={{ color: "rgb(94, 92, 92)" }}>Designation</span>
            </label>
          </div>

          <div className="col-sm-4 form-group">
            <label className="mb-3 top-label">
              <input
                type="text"
                id={"experience_employer_" + j}
                className={"form-control " + required}
                data-error="Enter employer name"
              />
              <span style={{ color: "rgb(94, 92, 92)" }}>
                Employer Name, City, State
              </span>
            </label>
          </div>

          <div className="col-sm-4 form-group">
            <label className="mb-3 top-label">
              <select
                className={"form-control " + required}
                id={"experience_country_" + j}
                data-error="Please select country"
              >
                <option value={""}>Select</option>
                {countries.map((c, k) => (
                  <option value={c.country_id} key={k}>
                    {c.country_name}
                  </option>
                ))}
              </select>
              {/*<input type="text" id={"experience_bed_capacity_" + j} className={"form-control " + required} data-error="Enter bed capacity" />*/}
              <span style={{ color: "rgb(94, 92, 92)" }}>Country</span>
            </label>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <hr />
            </div>
          </div>
        </div>
      );

      required = "";
    }

    return data;
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("candidateId", candidateId);
    setImage(URL.createObjectURL(e.target.files[0]));
    setLoading(true);

    /*var reader = new FileReader();

        reader.onload = function (e) {
            setImage(e.target.result);
        };*/

    //const imageSrc = URL.createObjectURL(file[0]);
    //setImage(image);

    /*post('admin/translators/document_upload', formData)
            .then(res => {
                _loadDocuments(1);
                alert("Successfully Uploaded!");
            }).catch(err => console.log(err));*/

    const headers = {
      "Contetnt-Type": "multipart/form-data",
    };

    axios
      .post(
        process.env.REACT_APP_BASE_URL + "candidates/avatar_upload",
        formData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setLoading(false);
        alert("Successfully Uploaded!");
        //window.location.href = "/profile";
      })
      .catch((error) => {
        setLoading(false);
        alert("Avatar upload failed, Please try agian");
      });
  };

  const plusSchool = () => {
    //console.log(schoolsDiv)
    //document.getElementById("schools_div").appendChild(schoolsDiv);
    var sDiv = "";
    setSchoolsArr([...schoolsArr, schoolsDiv]);

    setTimeout(function () {
      window.$(function () {
        const d = new Date();
        let year = d.getFullYear();

        window.$(".date-picker").datepicker({
          dateFormat: "MM yy",
          changeMonth: true,
          changeYear: true,
          showButtonPanel: true,
          maxDate: "+28D",
          yearRange: "1970:" + year,

          onClose: function (dateText, inst) {
            function isDonePressed() {
              return (
                window
                  .$("#ui-datepicker-div")
                  .html()
                  .indexOf(
                    "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover"
                  ) > -1
              );
            }
            if (isDonePressed()) {
              var month = window
                .$("#ui-datepicker-div .ui-datepicker-month :selected")
                .val();
              var year = window
                .$("#ui-datepicker-div .ui-datepicker-year :selected")
                .val();
              window
                .$(this)
                .val(
                  window.$.datepicker.formatDate(
                    "MM yy",
                    new Date(year, month, 1)
                  )
                );
            }
          },
        });

        window.$(".date-picker").focus(function () {
          window.$(".ui-datepicker-calendar").hide();
          window.$("#ui-datepicker-div").position({
            my: "center top",
            at: "center bottom",
            of: window.$(this),
          });
        });
      });
    }, 500);
  };

  const plusSchoolData = (type) => {
    var sDiv = "";
    setSchoolsArr([...schoolsArr, schoolsDiv]);

    if (type == "lower") {
      setSchoolsLowerArr([...schoolsLowerArr, schoolsDiv]);
    } else if (type == "upper") {
      setSchoolsUpperArr([...schoolsUpperArr, schoolsDiv]);
    } else if (type == "high") {
      setSchoolsHighArr([...schoolsHighArr, schoolsDiv]);
    }

    setTimeout(function () {
      window.$(function () {
        const d = new Date();
        let year = d.getFullYear();

        window.$(".date-picker").datepicker({
          dateFormat: "MM yy",
          changeMonth: true,
          changeYear: true,
          showButtonPanel: true,
          maxDate: "+28D",
          yearRange: "1970:" + year,

          onClose: function (dateText, inst) {
            function isDonePressed() {
              return (
                window
                  .$("#ui-datepicker-div")
                  .html()
                  .indexOf(
                    "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover"
                  ) > -1
              );
            }
            if (isDonePressed()) {
              var month = window
                .$("#ui-datepicker-div .ui-datepicker-month :selected")
                .val();
              var year = window
                .$("#ui-datepicker-div .ui-datepicker-year :selected")
                .val();
              window
                .$(this)
                .val(
                  window.$.datepicker.formatDate(
                    "MM yy",
                    new Date(year, month, 1)
                  )
                );
            }
          },
        });

        window.$(".date-picker").focus(function () {
          window.$(".ui-datepicker-calendar").hide();
          window.$("#ui-datepicker-div").position({
            my: "center top",
            at: "center bottom",
            of: window.$(this),
          });
        });
      });
    }, 500);
  };

  const plusCourse = () => {
    var limit = 3;
    if (myProfile.candidate_nurse_course.length > 0) {
      limit = 4 - myProfile.candidate_nurse_course.length;
    }
    if (courseArr.length < limit) {
      setCourseArr([...courseArr, courseDiv]);
    }

    setTimeout(function () {
      window.$(function () {
        const d = new Date();
        let year = d.getFullYear();

        window.$(".date-picker").datepicker({
          dateFormat: "MM yy",
          changeMonth: true,
          changeYear: true,
          showButtonPanel: true,
          maxDate: "+28D",
          yearRange: "1970:" + year,

          onClose: function (dateText, inst) {
            function isDonePressed() {
              return (
                window
                  .$("#ui-datepicker-div")
                  .html()
                  .indexOf(
                    "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover"
                  ) > -1
              );
            }
            if (isDonePressed()) {
              var month = window
                .$("#ui-datepicker-div .ui-datepicker-month :selected")
                .val();
              var year = window
                .$("#ui-datepicker-div .ui-datepicker-year :selected")
                .val();
              window
                .$(this)
                .val(
                  window.$.datepicker.formatDate(
                    "MM yy",
                    new Date(year, month, 1)
                  )
                );
            }
          },
        });

        window.$(".date-picker").focus(function () {
          window.$(".ui-datepicker-calendar").hide();
          window.$("#ui-datepicker-div").position({
            my: "center top",
            at: "center bottom",
            of: window.$(this),
          });
        });
      });
    }, 500);
  };

  const plusLanguageCourse = () => {
    setLanguageCourseArr([...languageCourseArr, languageCourseDiv]);

    setTimeout(function () {
      window.$(function () {
        const d = new Date();
        let year = d.getFullYear();

        window.$(".date-picker").datepicker({
          dateFormat: "MM yy",
          changeMonth: true,
          changeYear: true,
          showButtonPanel: true,
          maxDate: "+28D",
          yearRange: "1970:" + year,

          onClose: function (dateText, inst) {
            function isDonePressed() {
              return (
                window
                  .$("#ui-datepicker-div")
                  .html()
                  .indexOf(
                    "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover"
                  ) > -1
              );
            }
            if (isDonePressed()) {
              var month = window
                .$("#ui-datepicker-div .ui-datepicker-month :selected")
                .val();
              var year = window
                .$("#ui-datepicker-div .ui-datepicker-year :selected")
                .val();
              window
                .$(this)
                .val(
                  window.$.datepicker.formatDate(
                    "MM yy",
                    new Date(year, month, 1)
                  )
                );
            }
          },
        });

        window.$(".date-picker").focus(function () {
          window.$(".ui-datepicker-calendar").hide();
          window.$("#ui-datepicker-div").position({
            my: "center top",
            at: "center bottom",
            of: window.$(this),
          });
        });
      });
    }, 500);
  };

  const _saveLanguageSkills = () => {
    var language = document.getElementById("change_language_level").value;

    var raw = JSON.stringify({
      candidate_language_level: language,
    });

    setLoading(true);
    put("candidates/change_language", raw)
      .then(async function (response) {
        setLoading(false);
        setSaveBtn(false);
        _loadProfile();
        _model();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        _model();
      });
  };

  var languageLevels = {
    NOT_ENTROLLED: "Not yet enrolled",
    A1_ENTROLLED: "A1 Enrolled",
    A1_COMPLETE: "A2 Complete",
    B1_COMPLETE: "B1 Complete",
    B2_COMPLETE: "B2 Complete",
    C1_COMPLETE: "C1 Complete",
    C2_COMPLETE: "C2 Complete",
  };

  var languageCourseLevels = [
    { id: "Im Moment lerne A1 Niveau", val: "Im Moment lerne A1 Niveau" },
    { id: "Im Moment lerne A2 Niveau", val: "Im Moment lerne A2 Niveau" },
    { id: "Im Moment lerne B1 Niveau", val: "Im Moment lerne B1 Niveau" },
    { id: "mit Abschluss Zertifikat B1", val: "mit Abschluss Zertifikat B1" },
    { id: "Im Moment lerne B2 Niveau", val: "Im Moment lerne B2 Niveau" },
    { id: "mit Abschluss Zertifikat B2", val: "mit Abschluss Zertifikat B2" },
  ];

  var languageLevelsId = [
    { id: "NOT_ENTROLLED", val: "Not yet enrolled" },
    { id: "A1_ENTROLLED", val: "A1 Enrolled" },
    { id: "A1_COMPLETE", val: "A2 Complete" },
    { id: "B1_COMPLETE", val: "B1 Complete" },
    { id: "B2_COMPLETE", val: "B2 Complete" },
    /*{ id: "C1_COMPLETE", val: "C1 Complete" },
        { id: "C2_COMPLETE", val: "C2 Complete" },*/
  ];

  const _remove = (index) => {
    removeProduct(index);
  };

  const _removeLanguageCourse = (index) => {
    setLanguageCourseArr([
      ...languageCourseArr.slice(0, index),
      ...languageCourseArr.slice(index + 1, languageCourseArr.length),
    ]);
  };

  const removeProduct = (index) => {
    setCourseArr([
      ...courseArr.slice(0, index),
      ...courseArr.slice(index + 1, courseArr.length),
    ]);
  };

  const uploadResume = (e, data, id) => {
    const uploadedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("candidateId", candidateId);

    const headers = {
      "Contetnt-Type": "multipart/form-data",
    };
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "candidates/resume-upload",
        formData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        _loadProfile();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert("Upload failed, Please try agian");
      });
  };

  const handleAcknowledgeBrochure = () => {
    setLoading(true);
    put(
      "candidates/acknowledge_brochure",
      JSON.stringify({ acknowledged: true })
    )
      .then((response) => {
        setShowBrochureModal(false);
        setLoading(false);
        _loadProfile();
      })
      .catch((error) => {
        setLoading(false);
        alert("Failed to save acknowledgement");
      });
  };

  const _viewResume = () => {
    window.$("#largeLeftModalExample").modal("toggle");
  };

  const _model = () => {
    window.$("#exampleModal").modal("toggle");
  };

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const contentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "800px",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
  };

  const pdfContainerStyle = {
    flex: 1,
    minHeight: 0,
    overflow: "auto",
    marginBottom: "20px",
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "10px",
  };

  return (
    <>
      <Header />
      <Loader loading={loading} />

      <main>
        <div className="container-fluid">
          <div className="row">
            {/* Menu Start */}
            <SideBar
              mandateOne={true}
              mandateTwo={true}
              shouldShowPaymentStep={
                myProfile.candidate_degree != GNM &&
                myProfile.candidate_degree != NURSE &&
                myProfile.candidate_degree != REGISTERED_NURSE
              }
              step={step}
              license={myProfile.candidate_licence}
            />
            {/*  Menu End */}

            {showBrochureModal && (
              <div style={modalStyle}>
                <div style={contentStyle}>
                  <h2 style={{ marginBottom: "15px" }}>
                    Information Brochure 2024
                  </h2>

                  <div style={pdfContainerStyle}>
                    <iframe
                      src={`${process.env.PUBLIC_URL}/assets/2024_Infobroschuere_EN_fl.pdf`}
                      width="100%"
                      height="100%"
                      style={{
                        border: "none",
                        minHeight: "calc(90vh - 150px)", // Ensure minimum height
                      }}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      paddingTop: "15px",
                      borderTop: "1px solid #eee",
                    }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={acknowledged}
                        onChange={(e) => setAcknowledged(e.target.checked)}
                        id="acknowledgeCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="acknowledgeCheck"
                      >
                        I have read and understood the information brochure
                      </label>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      disabled={!acknowledged}
                      onClick={() => handleAcknowledgeBrochure()}
                    >
                      Save Acknowledgement
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Page Content Start */}
            <div className="col">
              {/* Title and Top Buttons Start */}
              <div className="page-title-container mb-3">
                <div className="row">
                  <div className="col mb-2">
                    <h1 className="mb-2 pb-0 display-4" id="title">
                      Profile
                    </h1>
                    <div className="text-muted font-heading text-small">
                      Profile Details
                    </div>
                  </div>

                  <div className="col-12 col-sm-auto d-flex align-items-center justify-content-end">
                    {candidateResume == 1 && (
                      <>
                        <button
                          type="button"
                          class="btn btn-outline-primary me-2"
                          onClick={() => _model()}
                        >
                          Update Your Language Level
                        </button>
                        <button
                          type="button"
                          className="me-2 btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                        >
                          <a
                            target={"_blank"}
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "candidates/download/en" +
                              candidateId
                            }
                          >
                            <i data-acorn-icon="download"></i>
                            <span> Download Resume </span>
                          </a>
                        </button>
                        {/*<button type="button" className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto">
                                                    <a target={"_blank"} href={process.env.REACT_APP_BASE_URL + "candidates/download/en" + candidateId}>
                                                        <i data-acorn-icon="download"></i>
                                                        <span> Download Resume English</span>
                                                    </a>
                                                </button>*/}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Title and Top Buttons End */}

              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Update Your Language Level
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        <div className=" col-sm-12 form-group">
                          <label className="mb-3 top-label">
                            <select
                              className="form-control"
                              id="change_language_level"
                            >
                              <option value="">Select</option>
                              {languageLevelsId.map((c, k) => (
                                <option value={c.id}>{c.val}</option>
                              ))}
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Select your level
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => _model()}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => _saveLanguageSkills()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="uploadDoc">
                        <img
                          className="img-fluid rounded-xl"
                          src={image == null ? avatar : image}
                          style={{ width: "100%", height: 200 }}
                        />
                      </label>
                      <div style={{ marginTop: 4, color: "red" }}>
                        Passport size photo : (600 x 600) px
                      </div>
                    </div>
                    <div className="col-sm-4 mb-1">
                      <h3
                        class="alert-heading"
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {myProfile.candidate_first_name}{" "}
                        {myProfile.candidate_last_name}
                      </h3>
                      <span>{myProfile.candidate_email}</span>
                      <br />
                      <span>{myProfile.candidate_phone}</span>
                      <div className="p0">
                        Current language level is{" "}
                        <span style={{ fontSize: 16 }}>
                          <b>
                            {
                              languageLevels[
                                myProfile.candidate_current_language_level
                              ]
                            }
                          </b>
                        </span>
                      </div>
                      <br />
                      <br />
                      <br />
                      <label
                        htmlFor="uploadDoc"
                        style={{ marginTop: 12 }}
                        className="btn btn-outline-info"
                      >
                        Change Profile Pic
                      </label>
                      <br />

                      <input
                        className="d-none btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                        accept="image/*"
                        type="file"
                        style={{
                          zIndex: 2,
                          top: 0,
                          left: 0,
                          filter: "alpha(opacity=0)",
                          msFilter:
                            "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
                        }}
                        name="file_source"
                        size="40"
                        onChange={(e) => uploadFile(e)}
                        id="uploadDoc"
                      />
                    </div>
                    <div className="col-sm-4 mb-1 d-none">
                      <div
                        class="alert alert-secondary"
                        role="alert"
                        style={{ backgroundColor: "transparent", padding: 0 }}
                      >
                        <h5 class="alert-heading p0 m0" style={{ margin: 0 }}>
                          <b>Update Your Language Level</b>
                        </h5>
                        <div className="p0">
                          Your current language level is{" "}
                          <span style={{ fontSize: 16 }}>
                            <b>
                              {
                                languageLevels[
                                  myProfile.candidate_current_language_level
                                ]
                              }
                            </b>
                          </span>
                        </div>
                        <hr />
                        <p class="mb-0">
                          <div className="row">
                            <div className=" col-sm-12 form-group">
                              <label className="mb-3 top-label">
                                <select
                                  className="form-control"
                                  id="change_language_level"
                                >
                                  <option value="">Select</option>
                                  {languageLevelsId.map((c, k) => (
                                    <option value={c.id}>{c.val}</option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Select your level
                                </span>
                              </label>
                            </div>
                            <div className=" col-sm-12 form-group">
                              <button
                                onClick={() => _saveLanguageSkills()}
                                type="button"
                                className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#largeRightModalExample"
                              >
                                <i data-acorn-icon="save"></i>
                                <span>Save</span>
                              </button>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="row" style={{ display: "none" }}>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1 mt-5">
                    <div
                      className="progress progress-striped"
                      style={{ height: 20 }}
                    >
                      <div
                        className="progress-bar progress-bar-success"
                        style={{ width: percentage }}
                      >
                        {" "}
                        {"Your Profile is " + percentage + " Completed."}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ display: "none" }}>
                  <div className="col-sm-12 mt-5">
                    <div
                      class="alert alert-primary"
                      role="alert"
                      style={{
                        border: "1px solid var(--primary)",
                        backgroundColor: "white",
                      }}
                    >
                      <h4 class="alert-heading">
                        <b>
                          <svg
                            style={{ marginTop: 13, marginRight: 10 }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="acorn-icons acorn-icons-message mb-3 d-inline-block text-primary"
                          >
                            <path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 10.5C18 11.9045 18 12.6067 17.6629 13.1111C17.517 13.3295 17.3295 13.517 17.1111 13.6629C16.6067 14 15.9045 14 14.5 14L10.4497 14C9.83775 14 9.53176 14 9.24786 14.0861C9.12249 14.1241 9.00117 14.1744 8.88563 14.2362C8.62399 14.376 8.40762 14.5924 7.97487 15.0251L5.74686 17.2531C5.47773 17.5223 5.34317 17.6568 5.2255 17.6452C5.17629 17.6404 5.12962 17.6211 5.0914 17.5897C5 17.5147 5 17.3244 5 16.9438L5 14.6C5 14.5071 5 14.4606 4.99384 14.4218C4.95996 14.2078 4.79216 14.04 4.57822 14.0062C4.53935 14 4.4929 14 4.4 14V14C4.0284 14 3.8426 14 3.68713 13.9754C2.83135 13.8398 2.16017 13.1687 2.02462 12.3129C2 12.1574 2 11.9716 2 11.6L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path>
                          </svg>
                          Update Your Language Skill
                        </b>
                      </h4>
                      <p>
                        Your current language level is{" "}
                        <span style={{ fontSize: 16 }}>
                          <b>
                            {
                              languageLevels[
                                myProfile.candidate_current_language_level
                              ]
                            }
                          </b>
                        </span>
                      </p>
                      <hr />
                      <p class="mb-0">
                        <div className="row">
                          <div className=" col-sm-4 form-group">
                            <label className="mb-3 top-label">
                              <select
                                className="form-control"
                                id="change_language_level"
                              >
                                <option value="">Select</option>
                                {languageLevelsId.map((c, k) => (
                                  <option value={c.id}>{c.val}</option>
                                ))}
                              </select>
                              <span
                                style={{
                                  color: "rgb(94, 92, 92)",
                                  textTransform: "capitalize",
                                }}
                              >
                                Select your level
                              </span>
                            </label>
                          </div>
                          <div className=" col-sm-4 form-group">
                            <button
                              onClick={() => _saveLanguageSkills()}
                              type="button"
                              className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                              data-bs-toggle="modal"
                              data-bs-target="#largeRightModalExample"
                            >
                              <i data-acorn-icon="save"></i>
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Modal Start*/}
                <div
                  className="modal modal-right large fade"
                  id="largeLeftModalExample"
                  tabIndex="-1"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="exampleModalLabelLeftLarge"
                        >
                          Document Preview
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <embed
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "documents/preview/" +
                            myProfile.candidate_profile_resume
                          }
                          type="application/pdf"
                          width={"100%"}
                          height={"700px"}
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End*/}

                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1 mt-3">
                  {myProfile.candidate_licence == "Y" && (
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fw-bold">
                          {myProfile.candidate_profile_resume != null
                            ? "Re- "
                            : ""}
                          Upload Your Resume
                        </h5>
                      </div>
                      <div className="card-body">
                        {myProfile.candidate_profile_resume != null && (
                          <div
                            class="alert alert-primary mb-4 fw-bold"
                            role="alert"
                          >
                            Successfully updated your resume,{" "}
                            <a
                              onClick={() => _viewResume()}
                              href="javascript:void(0);"
                              style={{ textDecoration: "underline" }}
                            >
                              Click here to view your Resume
                            </a>
                            .Please upload all documents in Document section.
                          </div>
                        )}

                        <input
                          type="file"
                          onChange={(e) => uploadResume(e)}
                          accept="application/pdf"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1 mt-3 text-center mt-4">
                  {myProfile.candidate_licence == "Y" && (
                    <span
                      style={{ textAlign: "center", color: "#0d6efd" }}
                      className="fw-bold"
                    >
                      -- OR Please fill the details --
                    </span>
                  )}
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1">
                  <div className="card mb-5 mt-5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="mb-3 top-label">
                            <input
                              type="text"
                              className="form-control required text-capitalize"
                              id="first_name"
                              defaultValue={myProfile.candidate_first_name}
                              data-error="Please enter first name"
                            />
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Name
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <input
                              type="text"
                              className="form-control required text-capitalize"
                              id="last_name"
                              defaultValue={myProfile.candidate_last_name}
                              data-error="Please enter last name"
                            />
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Last Name
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <input
                              type="email"
                              id="email"
                              className="form-control required"
                              defaultValue={myProfile.candidate_email}
                              readOnly
                            />
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Email
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              defaultValue={myProfile.candidate_phone}
                              readOnly
                            />
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Phone
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <input
                              max="2023-01-01"
                              defaultValue={myProfile.candidate_dob}
                              type="date"
                              id="dob"
                              className="form-control required"
                              data-error="Please enter date of birth"
                            />
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Date Of Birth
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <select
                              name="country"
                              id="country"
                              className="form-control required"
                              defaultValue={myProfile.candidate_residency}
                              data-error="Please select country or region of residence"
                            >
                              <option value="">Select</option>
                              {countries.map((c, k) => (
                                <option value={c.country_id} key={k}>
                                  {c.country_name}
                                </option>
                              ))}
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Country or region of residence
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <select
                              name="nationality"
                              id="nationality"
                              className="form-control required"
                              defaultValue={myProfile.candidate_nationality}
                              data-error="Please select nationality"
                            >
                              <option value="">Select</option>
                              {nationality.map((c, k) => (
                                <option value={c.nationality_id} key={k}>
                                  {c.nationality_name}
                                </option>
                              ))}
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Nationality
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          {/*<label className="mb-3 top-label">
                                                        <input id="passport_number" type="text" className="form-control required" data-error="Please enter passport number" defaultValue={myProfile.candidate_passport_details} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Passport number</span>
                                                    </label>

                                                    <label className="mb-3 top-label">
                                                        <input id="passport_expiry_date" type="text" defaultValue={myProfile.candidate_passport_expiry} className="form-control required" data-error="Please enter passport expiry date" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Passport Expiry Date</span>
                                                        </label>*/}

                          <label className="mb-3 top-label">
                            <textarea
                              id="present_address"
                              className="form-control required"
                              style={{ height: 126 }}
                              data-error="Please enter present address"
                              defaultValue={myProfile.candidate_address}
                            >
                              {myProfile.candidate_address}
                            </textarea>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Present address
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <textarea
                              id="present_address_passport"
                              className="form-control required"
                              style={{ height: 120 }}
                              data-error="Please enter permanent address"
                              defaultValue={
                                myProfile.candidate_passport_address
                              }
                            ></textarea>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Permanent address as in passport
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <select
                              className="form-control required"
                              id="gender"
                              data-error="Please select gender"
                            >
                              <option value="">Select</option>
                              <option value="m">Male</option>
                              <option value="f">Female</option>
                              <option value="t">Transgender</option>
                              <option value="o">Others</option>
                            </select>
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Gender
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <select
                              className="form-control required"
                              id="family_status"
                              data-error="Please select family status"
                            >
                              <option value="">Select</option>
                              <option value="s">Single</option>
                              <option value="m">Married</option>
                              <option value="w">Widowed</option>
                              <option value="d">Divorced</option>
                            </select>
                            <span style={{ color: "rgb(94, 92, 92)" }}>
                              Family Status
                            </span>
                          </label>

                          <label className="mb-3 top-label">
                            <input
                              type="text"
                              id="birth_place"
                              className="form-control required"
                              data-error="Please enter birth place"
                              defaultValue={
                                myProfile.candidate_birth_place ?? ""
                              }
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Birth Place
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-5 mt-5">
                    <div className="card-body">
                      <h3 className="mb-2 pb-0 display-6" id="title">
                        Educational Details
                      </h3>
                    </div>
                  </div>

                  {myProfile.candidate_degree == "STUDENT" &&
                    candidateSchools.length == 0 && (
                      <div className="card mb-5 mt-5">
                        <div className="card-body">
                          <div id="schools_div">
                            <h3 class="mb-5 mt-5 pb-0 display-8" id="title">
                              School Details - Lower Primary (1 to 4 Class) [{" "}
                              <a
                                href="javascript:void(0);"
                                onClick={() => plusSchoolData("lower")}
                              >
                                Add New School +{" "}
                              </a>{" "}
                              ]
                            </h3>
                            <div className="row">
                              <div className="col-sm-4 mb-3 top-label">
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                  className="text-break"
                                >
                                  Start Date
                                </span>
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="school_start"
                                  className="form-control required date-picker"
                                  data-error="Please select shool starting year"
                                  name="school_start[]"
                                />
                              </div>

                              <div className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="school_end"
                                  className="form-control required date-picker"
                                  data-error="Please select shool ending year"
                                  name="school_end[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  End Date
                                </span>
                              </div>

                              <div className="col-sm-2 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="class_from"
                                  className="form-control required"
                                  data-error="Please enter class From"
                                  name="class_from[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  Class From
                                </span>
                              </div>

                              <div className="col-sm-2 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="class_to"
                                  className="form-control required"
                                  data-error="Please enter class To"
                                  name="class_to[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  Class To
                                </span>
                              </div>

                              <div className="col-sm-9 mb-9 top-label">
                                <input
                                  type="text"
                                  id="school"
                                  className="form-control required"
                                  data-error="Please enter school name"
                                  name="school[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  School
                                </span>
                              </div>

                              <label className="col-sm-3 mb-3 top-label">
                                <select
                                  name="school_country[]"
                                  id="sccountry"
                                  className="form-control required"
                                  data-error="Please select country"
                                >
                                  <option value="">Select</option>
                                  {countries.map((c, k) => (
                                    <option value={c.country_id} key={k}>
                                      {c.country_name}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country
                                </span>
                              </label>
                            </div>

                            {schoolsLowerArr.map((d, k) => (
                              <div className="row">{Parser(d)}</div>
                            ))}

                            <hr />

                            <h3 class="mb-5 mt-1 pb-0 display-8" id="title">
                              School Details - (5 to 10 Class) [{" "}
                              <a
                                href="javascript:void(0);"
                                onClick={() => plusSchoolData("upper")}
                              >
                                Add New School +{" "}
                              </a>{" "}
                              ]
                            </h3>
                            <div className="row">
                              <div className="col-sm-4 mb-3 top-label">
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                  className="text-break"
                                >
                                  Start Date
                                </span>
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="school_start_up"
                                  className="form-control required date-picker"
                                  data-error="Please select shool starting year"
                                  name="school_start[]"
                                />
                              </div>

                              <div className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="school_end_up"
                                  className="form-control required date-picker"
                                  data-error="Please select shool ending year"
                                  name="school_end[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  End Date
                                </span>
                              </div>

                              <div className="col-sm-2 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="class_from_up"
                                  className="form-control required"
                                  data-error="Please enter class From"
                                  name="class_from[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  Class From
                                </span>
                              </div>

                              <div className="col-sm-2 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="class_to_up"
                                  className="form-control required"
                                  data-error="Please enter class To"
                                  name="class_to[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  Class To
                                </span>
                              </div>

                              <div className="col-sm-9 mb-9 top-label">
                                <input
                                  type="text"
                                  id="school_up"
                                  className="form-control required"
                                  data-error="Please enter school name"
                                  name="school[]"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  School
                                </span>
                              </div>

                              <label className="col-sm-3 mb-3 top-label">
                                <select
                                  name="school_country[]"
                                  id="sccountry_up"
                                  className="form-control required"
                                  data-error="Please select country"
                                >
                                  <option value="">Select</option>
                                  {countries.map((c, k) => (
                                    <option value={c.country_id} key={k}>
                                      {c.country_name}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country
                                </span>
                              </label>
                            </div>

                            {schoolsUpperArr.map((d, k) => (
                              <div className="row">{Parser(d)}</div>
                            ))}

                            <hr />
                          </div>
                        </div>
                      </div>
                    )}

                  {myProfile.candidate_degree == "STUDENT" && (
                    <div style={{ display: "none" }}>
                      <div className="row" id="schools">
                        <div className="col-sm-4 mb-3 top-label">
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                            className="text-break"
                          >
                            Start Date
                          </span>
                          <input
                            autocomplete="off"
                            type="text"
                            id="school_start_lp"
                            className="form-control required date-picker"
                            data-error="Please select shool starting year"
                            name="school_start[]"
                          />
                        </div>

                        <div className="col-sm-4 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            id="school_end_lp"
                            className="form-control required date-picker"
                            data-error="Please select shool ending year"
                            name="school_end[]"
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            End Date
                          </span>
                        </div>

                        <div className="col-sm-2 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            id="class_from_lp"
                            className="form-control required"
                            data-error="Please enter class From"
                            name="class_from[]"
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                              backgroundColor: "transparent",
                            }}
                          >
                            Class From
                          </span>
                        </div>

                        <div className="col-sm-2 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            id="class_to_lp"
                            className="form-control required"
                            data-error="Please enter class To"
                            name="class_to[]"
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                              backgroundColor: "transparent",
                            }}
                          >
                            Class To
                          </span>
                        </div>

                        <div className="col-sm-9 mb-9 top-label">
                          <input
                            type="text"
                            id="school_lp"
                            className="form-control required"
                            data-error="Please enter school name"
                            name="school[]"
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            School
                          </span>
                        </div>

                        <label className="col-sm-3 mb-3 top-label">
                          <select
                            name="school_country[]"
                            id="sccountry_lp"
                            className="form-control required"
                            data-error="Please select country"
                          >
                            <option value="">Select</option>
                            {countries.map((c, k) => (
                              <option value={c.country_id} key={k}>
                                {c.country_name}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Country
                          </span>
                        </label>
                      </div>
                    </div>
                  )}

                  {myProfile.candidate_degree == "STUDENT" &&
                    candidateSchools.length > 0 && (
                      <>
                        <div className="card">
                          <div className="card-body">
                            <h3 class="mb-2 mt-1 pb-0 display-8" id="title">
                              School Details UP School (Class 1 to 4) [{" "}
                              <a
                                href="javascript:void(0);"
                                onClick={() => plusSchoolData("lower")}
                              >
                                Add New School +{" "}
                              </a>{" "}
                              ]
                            </h3>
                            <br />
                            {candidateSchools.map(
                              (c, k) =>
                                c.class_from >= 1 &&
                                c.class_to <= 4 && (
                                  <>
                                    <div className="row">
                                      <div className="col-sm-4 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school_start}
                                          type="text"
                                          id="school_start"
                                          className="form-control required date-picker"
                                          data-error="Please select shool starting year"
                                          name="school_start[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                          className="text-break"
                                        >
                                          Start Date
                                        </span>
                                      </div>

                                      <div className="col-sm-4 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school_end}
                                          type="text"
                                          id="school_end"
                                          className="form-control required date-picker"
                                          data-error="Please select shool ending year"
                                          name="school_end[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          End Date
                                        </span>
                                      </div>

                                      <div className="col-sm-2 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.class_from}
                                          type="text"
                                          id="class_from"
                                          className="form-control required"
                                          data-error="Please enter class From"
                                          name="class_from[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Class From
                                        </span>
                                      </div>

                                      <div className="col-sm-2 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.class_to}
                                          type="text"
                                          id="class_to"
                                          className="form-control required"
                                          data-error="Please enter class To"
                                          name="class_to[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Class To
                                        </span>
                                      </div>

                                      <div className="col-sm-8 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school}
                                          type="text"
                                          id="school"
                                          className="form-control required"
                                          data-error="Please enter school name"
                                          name="school[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          School
                                        </span>
                                      </div>

                                      <label className="col-sm-3 mb-3 top-label">
                                        <select
                                          defaultValue={c.country}
                                          name="school_country[]"
                                          id="sccountry"
                                          className="form-control required"
                                          data-error="Please select country"
                                        >
                                          <option value="">Select</option>
                                          {countries.map((c, k) => (
                                            <option
                                              value={c.country_id}
                                              key={k}
                                            >
                                              {c.country_name}
                                            </option>
                                          ))}
                                        </select>
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Country
                                        </span>
                                      </label>
                                    </div>

                                    <hr />
                                  </>
                                )
                            )}

                            {schoolsLowerArr.map((d, k) => (
                              <div className="row">{Parser(d)}</div>
                            ))}
                          </div>
                        </div>

                        <div className="card mt-5">
                          <div className="card-body">
                            <h3 class="mb-2 mt-1 pb-0 display-8" id="title">
                              School Details (Class 5 to 10) [{" "}
                              <a
                                href="javascript:void(0);"
                                onClick={() => plusSchoolData("upper")}
                              >
                                Add New School +{" "}
                              </a>{" "}
                              ]
                            </h3>
                            <br />
                            {candidateSchools.map(
                              (c, k) =>
                                c.class_from >= 5 &&
                                c.class_to <= 10 && (
                                  <>
                                    <div className="row">
                                      <div className="col-sm-4 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school_start}
                                          type="text"
                                          id="school_start"
                                          className="form-control required date-picker"
                                          data-error="Please select shool starting year"
                                          name="school_start[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                          className="text-break"
                                        >
                                          Start Date
                                        </span>
                                      </div>

                                      <div className="col-sm-4 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school_end}
                                          type="text"
                                          id="school_end"
                                          className="form-control required date-picker"
                                          data-error="Please select shool ending year"
                                          name="school_end[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          End Date
                                        </span>
                                      </div>

                                      <div className="col-sm-2 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.class_from}
                                          type="text"
                                          id="class_from"
                                          className="form-control required"
                                          data-error="Please enter class From"
                                          name="class_from[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Class From
                                        </span>
                                      </div>

                                      <div className="col-sm-2 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.class_to}
                                          type="text"
                                          id="class_to"
                                          className="form-control required"
                                          data-error="Please enter class To"
                                          name="class_to[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Class To
                                        </span>
                                      </div>

                                      <div className="col-sm-8 mb-3 top-label">
                                        <input
                                          autocomplete="off"
                                          defaultValue={c.school}
                                          type="text"
                                          id="school"
                                          className="form-control required"
                                          data-error="Please enter school name"
                                          name="school[]"
                                        />
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          School
                                        </span>
                                      </div>

                                      <label className="col-sm-3 mb-3 top-label">
                                        <select
                                          defaultValue={c.country}
                                          name="school_country[]"
                                          id="sccountry"
                                          className="form-control required"
                                          data-error="Please select country"
                                        >
                                          <option value="">Select</option>
                                          {countries.map((c, k) => (
                                            <option
                                              value={c.country_id}
                                              key={k}
                                            >
                                              {c.country_name}
                                            </option>
                                          ))}
                                        </select>
                                        <span
                                          style={{
                                            color: "rgb(94, 92, 92)",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Country
                                        </span>
                                      </label>
                                    </div>
                                  </>
                                )
                            )}

                            {schoolsUpperArr.map((d, k) => (
                              <div className="row">{Parser(d)}</div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                  <div className="card mt-5">
                    <div className="card-body">
                      <h3 class="mb-5 pb-0 display-8" id="title">
                        Higher Secondary Course Details
                      </h3>
                      <div className="row">
                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            id="hs_start"
                            className="form-control required date-picker"
                            data-error="Higher secondary starting month & year"
                            defaultValue={
                              myProfile.candidate_education_details.course_start
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Start Date
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            id="hs_end"
                            className="form-control required date-picker"
                            data-error="Higher secondary ending month & year"
                            defaultValue={
                              myProfile.candidate_education_details.course_end
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            End Date
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            type="text"
                            id="hs_school"
                            className="form-control required"
                            data-error="Please enter higher secondary school name"
                            defaultValue={
                              myProfile.candidate_education_details.school_name
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Higher secondary school name
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            type="text"
                            id="hs_universiry"
                            className="form-control required"
                            data-error="Enter your University/ Board name"
                            defaultValue={
                              myProfile.candidate_education_details.board_name
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            University/ Board name
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            type="text"
                            id="hs_state"
                            className="form-control required"
                            data-error="Enter state"
                            defaultValue={
                              myProfile.candidate_education_details.state
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            State
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <select
                            name="hs_country"
                            id="hs_country"
                            className="form-control required"
                            data-error="Please select country"
                          >
                            <option value="">Select</option>
                            {countries.map((c, k) => (
                              <option value={c.country_id} key={k}>
                                {c.country_name}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Country
                          </span>
                        </label>
                      </div>

                      <div style={{ display: "none" }}>
                        <div className="row" id="nursingCourse">
                          <label className="col-sm-4 mb-3 top-label">
                            <input
                              autocomplete="off"
                              type="text"
                              name="nc_start[]"
                              id="nc_start_"
                              className="form-control required date-picker"
                              data-error="Nursing starting month & year"
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Start Date
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <input
                              autocomplete="off"
                              type="text"
                              name="nc_end[]"
                              id="nc_end_"
                              className="form-control required date-picker"
                              data-error="Nursing ending month & year"
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              End Date
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <select
                              name="nc_course[]"
                              className="form-control required"
                              id="nc_course_"
                              data-error="Please select nursing course"
                            >
                              <option value="">Select</option>
                              <option value="1">
                                B.Sc Nursing 4 years course
                              </option>
                              <option value="2">
                                M.Sc Nursing 2 years course
                              </option>
                              <option value="3">
                                Diploma in Nursing/GNM 3.5 years course
                              </option>
                              <option value="4">
                                Post Basic B.Sc 2 years course
                              </option>
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Nursing Course
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <input
                              type="text"
                              name="nc_college[]"
                              id="nc_college_"
                              className="form-control required"
                              data-error="Enter your college"
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              College name
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <input
                              type="text"
                              name="nc_university[]"
                              id="nc_universiry_"
                              className="form-control required"
                              data-error="Enter your University/ Board name"
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              University/ Board name
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <input
                              type="text"
                              name="nc_state[]"
                              id="nc_state_"
                              className="form-control required"
                              data-error="Enter state"
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              State
                            </span>
                          </label>

                          <label className="col-sm-4 mb-3 top-label">
                            <select
                              name="nc_country[]"
                              id="nc_country_"
                              className="form-control required"
                              data-error="Please select country"
                            >
                              <option value="">Select</option>
                              {countries.map((c, k) => (
                                <option value={c.country_id} key={k}>
                                  {c.country_name}
                                </option>
                              ))}
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Country
                            </span>
                          </label>
                          <hr />
                        </div>
                      </div>

                      {myProfile.candidate_degree != "STUDENT" && (
                        <>
                          <div class="d-flex justify-content-between">
                            <h3 class="mb-5 mt-5 pb-0 display-8" id="title">
                              Nursing Course Details
                            </h3>
                            <div className="mb-5 mt-5 pb-0">
                              <a
                                href="javascript:void(0);"
                                onClick={() => plusCourse()}
                              >
                                Add New Course +{" "}
                              </a>
                            </div>
                          </div>
                          {myProfile.candidate_nurse_course.length > 0 &&
                            myProfile.candidate_nurse_course.map((c, k) => (
                              <div className="row">
                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    autocomplete="off"
                                    type="text"
                                    name="nc_start[]"
                                    id="nc_start"
                                    className="form-control required date-picker"
                                    data-error="Nursing starting month & year"
                                    defaultValue={c.course_start}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Start Date
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    autocomplete="off"
                                    type="text"
                                    name="nc_end[]"
                                    id="nc_end"
                                    className="form-control required date-picker"
                                    data-error="Nursing ending month & year"
                                    defaultValue={c.course_end}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    End Date
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <select
                                    name="nc_course[]"
                                    className="form-control required"
                                    id="nc_course"
                                    data-error="Please select nursing course"
                                    defaultValue={c.course}
                                  >
                                    <option value="">Select</option>
                                    <option value="1">
                                      B.Sc Nursing 4 years course
                                    </option>
                                    <option value="2">
                                      M.Sc Nursing 2 years course
                                    </option>
                                    <option value="3">
                                      Diploma in Nursing/GNM 3.5 years course
                                    </option>
                                    <option value="4">
                                      Post Basic B.Sc 2 years course
                                    </option>
                                    <option value="5">
                                      Diploma in Nursing/GNM 3 years course
                                    </option>
                                  </select>
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Nursing Course
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="nc_college[]"
                                    id="nc_college"
                                    className="form-control required"
                                    data-error="Enter your college"
                                    defaultValue={c.college_name}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    College name
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="nc_university[]"
                                    id="nc_universiry"
                                    className="form-control required"
                                    data-error="Enter your University/ Board name"
                                    defaultValue={c.board_name}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    University/ Board name
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="nc_state[]"
                                    id="nc_state"
                                    className="form-control required"
                                    data-error="Enter state"
                                    defaultValue={c.state}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    State
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <select
                                    name="nc_country[]"
                                    id="nc_country"
                                    className="form-control required"
                                    data-error="Please select country"
                                    defaultValue={c.country}
                                  >
                                    <option value="">Select</option>
                                    {countries.map((c, k) => (
                                      <option value={c.country_id} key={k}>
                                        {c.country_name}
                                      </option>
                                    ))}
                                  </select>
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Country
                                  </span>
                                </label>
                                <hr />
                              </div>
                            ))}

                          {myProfile.candidate_nurse_course.length == 0 && (
                            <div className="row">
                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  name="nc_start[]"
                                  id="nc_start"
                                  className="form-control required date-picker"
                                  data-error="Nursing starting month & year"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Start Date
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  name="nc_end[]"
                                  id="nc_end"
                                  className="form-control required date-picker"
                                  data-error="Higher secondary ending month & year"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  End Date
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <select
                                  name="nc_course[]"
                                  className="form-control required"
                                  id="nc_course"
                                  data-error="Please select nursing course"
                                >
                                  <option value="">Select</option>
                                  <option value="1">
                                    B.Sc Nursing 4 years course
                                  </option>
                                  <option value="2">
                                    M.Sc Nursing 2 years course
                                  </option>
                                  <option value="3">
                                    Diploma in Nursing/GNM 3.5 years course
                                  </option>
                                  <option value="5">
                                    Diploma in Nursing/GNM 3 years course
                                  </option>
                                  <option value="4">
                                    Post Basic B.Sc 2 years course
                                  </option>
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Nursing Course
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="nc_college[]"
                                  id="nc_college"
                                  className="form-control required"
                                  data-error="Enter your college"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  College name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="nc_university[]"
                                  id="nc_universiry"
                                  className="form-control required"
                                  data-error="Enter your University/ Board name"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  University/ Board name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="nc_state[]"
                                  id="nc_state"
                                  className="form-control required"
                                  data-error="Enter state"
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  State
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <select
                                  name="nc_country[]"
                                  id="nc_country"
                                  className="form-control required"
                                  data-error="Please select country"
                                >
                                  <option value="">Select</option>
                                  {countries.map((c, k) => (
                                    <option value={c.country_id} key={k}>
                                      {c.country_name}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country
                                </span>
                              </label>
                              <hr />
                            </div>
                          )}

                          {courseArr.map((d, k) => (
                            <div className="row">
                              <div className="col-sm-12 text-end mb-2">
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => _remove(k)}
                                >
                                  Remove
                                </a>
                              </div>
                              {Parser(d)}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  <div style={{ display: "none" }} id="language_course">
                    <div className="row">
                      <label className="col-sm-4 mb-3 top-label">
                        <input
                          autocomplete="off"
                          type="text"
                          name="lc_start[]"
                          id="lc_start_t"
                          className="form-control required date-picker"
                          data-error="Language course start date"
                          defaultValue={
                            myProfile.candidate_language_course.lc_start
                          }
                        />
                        <span
                          style={{
                            color: "rgb(94, 92, 92)",
                            textTransform: "capitalize",
                          }}
                        >
                          Start Date
                        </span>
                      </label>

                      <div className="col-sm-4 mb-3">
                        <label className="top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            name="lc_end[]"
                            id="lc_end_t"
                            className="form-control required date-picker"
                            data-error="Language course end date"
                            defaultValue={
                              myProfile.candidate_language_course.lc_end
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            End Date
                          </span>
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            name="till_now[]"
                            id="till_now_t"
                            value={1}
                          />{" "}
                          Till Now
                        </label>
                      </div>

                      <label className="col-sm-4 mb-3 top-label">
                        <select
                          name="lc_language_level[]"
                          id="lc_language_level_t"
                          className="form-control required"
                          data-error="Please select language level"
                        >
                          <option value="">Select</option>
                          {languageCourseLevels.map((c, k) => (
                            <option value={c.id}>{c.val}</option>
                          ))}
                        </select>
                        <span
                          style={{
                            color: "rgb(94, 92, 92)",
                            textTransform: "capitalize",
                          }}
                        >
                          Level
                        </span>
                      </label>

                      <label className="col-sm-4 mb-3 top-label">
                        <input
                          type="text"
                          name="lc_school[]"
                          id="lc_school_t"
                          className="form-control required"
                          data-error="Enter your school name"
                        />
                        <span
                          style={{
                            color: "rgb(94, 92, 92)",
                            textTransform: "capitalize",
                          }}
                        >
                          School name
                        </span>
                      </label>

                      <label className="col-sm-4 mb-3 top-label">
                        <input
                          type="text"
                          name="lc_state[]"
                          id="lc_state_t"
                          className="form-control required"
                          data-error="Enter state"
                        />
                        <span
                          style={{
                            color: "rgb(94, 92, 92)",
                            textTransform: "capitalize",
                          }}
                        >
                          State
                        </span>
                      </label>

                      <label className="col-sm-4 mb-3 top-label">
                        <select
                          name="lc_country[]"
                          id="lc_country_t"
                          className="form-control required"
                          data-error="Please select country"
                        >
                          <option value="">Select</option>
                          {countries.map((c, k) => (
                            <option value={c.country_id} key={k}>
                              {c.country_name}
                            </option>
                          ))}
                        </select>
                        <span
                          style={{
                            color: "rgb(94, 92, 92)",
                            textTransform: "capitalize",
                          }}
                        >
                          Country
                        </span>
                      </label>
                    </div>
                  </div>

                  {myProfile.candidate_degree == "STUDENT" &&
                    candidateCollege.length == 0 && (
                      <>
                        <div className="card mb-5 mt-5">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <h5 className="mb-2 pb-0 display-8" id="title">
                                College Course Details
                              </h5>
                            </div>

                            <hr />

                            <div className="row">
                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  name="college_start[]"
                                  id="lc_start"
                                  className="form-control date-picker"
                                  data-error="College course start date"
                                  defaultValue={
                                    myProfile.candidate_language_course.lc_start
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Start Date
                                </span>
                              </label>

                              <div className="col-sm-4 mb-3">
                                <label className="top-label">
                                  <input
                                    autocomplete="off"
                                    type="text"
                                    name="college_end[]"
                                    id="lc_end"
                                    className="form-control date-picker"
                                    data-error="Language course end date"
                                    defaultValue={
                                      myProfile.candidate_language_course.lc_end
                                    }
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    End Date
                                  </span>
                                </label>
                                <label style={{ display: "none" }}>
                                  <input
                                    type="checkbox"
                                    name="till_now[]"
                                    id="till_now"
                                    value={1}
                                  />{" "}
                                  Till Now
                                </label>
                              </div>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_school[]"
                                  id="college_school[]"
                                  className="form-control"
                                  data-error="Enter your school name"
                                  defaultValue={
                                    myProfile.candidate_language_course
                                      .lc_school
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  College name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_course[]"
                                  id="college_course[]"
                                  className="form-control"
                                  data-error="Enter your school name"
                                  defaultValue={
                                    myProfile.candidate_language_course
                                      .lc_school
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Course name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_state[]"
                                  id="college_state"
                                  className="form-control"
                                  data-error="Enter state"
                                  defaultValue={
                                    myProfile.candidate_language_course.lc_state
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  State
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <select
                                  name="college_country[]"
                                  id="college_country"
                                  className="form-control"
                                  data-error="Please select country"
                                >
                                  <option value="">Select</option>
                                  {countries.map((c, k) => (
                                    <option value={c.country_id} key={k}>
                                      {c.country_name}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country
                                </span>
                              </label>
                            </div>

                            <hr />

                            <div className="row">
                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  autocomplete="off"
                                  type="text"
                                  name="college_start[]"
                                  id="lc_start"
                                  className="form-control date-picker"
                                  data-error="College course start date"
                                  defaultValue={
                                    myProfile.candidate_language_course.lc_start
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Start Date
                                </span>
                              </label>

                              <div className="col-sm-4 mb-3">
                                <label className="top-label">
                                  <input
                                    autocomplete="off"
                                    type="text"
                                    name="college_end[]"
                                    id="lc_end"
                                    className="form-control date-picker"
                                    data-error="Language course end date"
                                    defaultValue={
                                      myProfile.candidate_language_course.lc_end
                                    }
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    End Date
                                  </span>
                                </label>
                                <label style={{ display: "none" }}>
                                  <input
                                    type="checkbox"
                                    name="till_now[]"
                                    id="till_now"
                                    value={1}
                                  />{" "}
                                  Till Now
                                </label>
                              </div>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_school[]"
                                  id="college_school"
                                  className="form-control"
                                  data-error="Enter your school name"
                                  defaultValue={
                                    myProfile.candidate_language_course
                                      .lc_school
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  College name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_course[]"
                                  id="college_school"
                                  className="form-control"
                                  data-error="Enter your school name"
                                  defaultValue={
                                    myProfile.candidate_language_course
                                      .lc_school
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Course name
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <input
                                  type="text"
                                  name="college_state[]"
                                  id="college_state"
                                  className="form-control"
                                  data-error="Enter state"
                                  defaultValue={
                                    myProfile.candidate_language_course.lc_state
                                  }
                                />
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  State
                                </span>
                              </label>

                              <label className="col-sm-4 mb-3 top-label">
                                <select
                                  name="college_country[]"
                                  id="college_country"
                                  className="form-control"
                                  data-error="Please select country"
                                >
                                  <option value="">Select</option>
                                  {countries.map((c, k) => (
                                    <option value={c.country_id} key={k}>
                                      {c.country_name}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  style={{
                                    color: "rgb(94, 92, 92)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {myProfile.candidate_degree == "STUDENT" &&
                    candidateCollege.length > 0 && (
                      <>
                        <div className="card mb-5 mt-5">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <h5 className="mb-2 pb-0 display-8" id="title">
                                College Course Details
                              </h5>
                            </div>

                            <hr />

                            {candidateCollege.map((c, k) => (
                              <div className="row">
                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    autocomplete="off"
                                    type="text"
                                    name="college_start[]"
                                    id="lc_start"
                                    className="form-control date-picker"
                                    data-error="College course start date"
                                    defaultValue={c.course_start}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Start Date
                                  </span>
                                </label>

                                <div className="col-sm-4 mb-3">
                                  <label className="top-label">
                                    <input
                                      autocomplete="off"
                                      type="text"
                                      name="college_end[]"
                                      id="lc_end"
                                      className="form-control date-picker"
                                      data-error="College course end date"
                                      defaultValue={c.course_end}
                                    />
                                    <span
                                      style={{
                                        color: "rgb(94, 92, 92)",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      End Date
                                    </span>
                                  </label>
                                  <label style={{ display: "none" }}>
                                    <input
                                      type="checkbox"
                                      name="till_now[]"
                                      id="till_now"
                                      value={1}
                                    />{" "}
                                    Till Now
                                  </label>
                                </div>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="college_school[]"
                                    id="college_school[]"
                                    className="form-control"
                                    data-error="Enter your college name"
                                    defaultValue={c.college_school}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    College name
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="college_course[]"
                                    id="college_course[]"
                                    className="form-control"
                                    data-error="Enter your couyrtse name"
                                    defaultValue={c.college_course}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Course name
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <input
                                    type="text"
                                    name="college_state[]"
                                    id="college_state"
                                    className="form-control"
                                    data-error="Enter state"
                                    defaultValue={c.college_state}
                                  />
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    State
                                  </span>
                                </label>

                                <label className="col-sm-4 mb-3 top-label">
                                  <select
                                    name="college_country[]"
                                    id="college_country"
                                    className="form-control"
                                    data-error="Please select country"
                                    defaultValue={c.country}
                                  >
                                    <option value="">Select</option>
                                    {countries.map((co, key) => (
                                      <option value={co.country_id} key={key}>
                                        {co.country_name}
                                      </option>
                                    ))}
                                  </select>
                                  <span
                                    style={{
                                      color: "rgb(94, 92, 92)",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Country
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                  <div className="card mb-5 mt-5">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-2 pb-0 display-6" id="title">
                          German Language Course
                        </h3>
                      </div>

                      <hr />

                      <div className="row">
                        <label className="col-sm-4 mb-3 top-label">
                          <select
                            name="language_course_language_level[]"
                            id="lc_language_level"
                            className="form-control"
                            data-error="Please select language level"
                          >
                            <option value="">Select</option>
                            {languageCourseLevels.map((c, k) => (
                              <option value={c.id} key={k}>
                                {c.val}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Current Level
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            autocomplete="off"
                            type="text"
                            name="language_course_start[]"
                            id="language_course_start"
                            className="form-control date-picker"
                            data-error="Language course start date"
                            defaultValue={
                              myProfile.candidate_language_course.lc_start
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Start Date
                          </span>
                        </label>

                        <div className="col-sm-4 mb-3">
                          <label className="top-label">
                            <input
                              autocomplete="off"
                              type="text"
                              name="lc_end[]"
                              id="language_course_end"
                              className="form-control date-picker"
                              data-error="Language course end date"
                              defaultValue={
                                myProfile.candidate_language_course.lc_end
                              }
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              End Date
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                myProfile.candidate_language_course.till_now ==
                                  "Ab" ||
                                myProfile.candidate_language_course.till_now ==
                                  "bis jetzt"
                                  ? true
                                  : false
                              }
                              name="till_now[]"
                              id="language_course_till_now"
                              value={1}
                            />{" "}
                            Till Now
                          </label>
                        </div>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            type="text"
                            name="language_course_school[]"
                            id="language_course_school"
                            className="form-control"
                            data-error="Enter your school name"
                            defaultValue={
                              myProfile.candidate_language_course.lc_school
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            School name
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <input
                            type="text"
                            name="language_course_state[]"
                            id="language_course_state"
                            className="form-control"
                            data-error="Enter state"
                            defaultValue={
                              myProfile.candidate_language_course.lc_state
                            }
                          />
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            State
                          </span>
                        </label>

                        <label className="col-sm-4 mb-3 top-label">
                          <select
                            name="language_course_country[]"
                            id="language_course_country"
                            className="form-control"
                            data-error="Please select country"
                          >
                            <option value="">Select</option>
                            {countries.map((c, k) => (
                              <option value={c.country_id} key={k}>
                                {c.country_name}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{
                              color: "rgb(94, 92, 92)",
                              textTransform: "capitalize",
                            }}
                          >
                            Country
                          </span>
                        </label>
                      </div>

                      {languageCourseArr.map((d, k) => (
                        <div className="row">
                          <div className="col-sm-12 text-end mb-2">
                            <a
                              href="javascript:void(0);"
                              onClick={() => _removeLanguageCourse(k)}
                            >
                              Remove
                            </a>
                          </div>
                          {Parser(d)}
                        </div>
                      ))}
                    </div>
                  </div>

                  {
                    //myProfile.candidate_degree != 'STUDENT' &&
                    <div className="card mb-5 mt-5">
                      <div className="card-body">
                        <h3 className="mb-2 pb-0 display-6" id="title">
                          Professional experience
                        </h3>
                        <hr />

                        <div className="row">
                          <div className=" col-sm-12 form-group">
                            <label className="mb-3 top-label">
                              <input
                                type="number"
                                id="nc_total_experience"
                                data-error="Please select total year experience"
                                className="form-control"
                              />
                              {/*<select className="form-control" id="nc_total_experience" data-error="Please select total year experience">
                                                                <option value="0">Select</option>
                                                                <option value="0">Less than 1 year</option>
                                                                {experiences()}
                                                                <option value="21">21+ Years</option>
                                                            </select>*/}
                              <span
                                style={{
                                  color: "rgb(94, 92, 92)",
                                  textTransform: "capitalize",
                                }}
                              >
                                Enter your total years of experience
                              </span>
                            </label>
                          </div>
                        </div>

                        {experienceDetails()}
                      </div>
                    </div>
                  }
                  <div className="card mb-5 mt-5">
                    <div className="card-body">
                      <h3 className="mb-2 pb-0 display-6" id="title">
                        Language Skills
                      </h3>
                      <hr />
                      <div className="row">
                        <div className=" col-sm-8">
                          <label className="w-100 mb-3 top-label">
                            <input
                              type="text"
                              name="mother_tongue"
                              id="mother_tongue"
                              className="form-control"
                              data-error="Enter mother tongue"
                              defaultValue={myProfile.candidate_mother_tongue}
                            />
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Mother Tongue
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" col-sm-4 form-group">
                          <label className="mb-3 top-label">
                            <select
                              className="form-control"
                              id="language"
                              data-error="Please select language"
                            >
                              <option value="">Select</option>
                              {speaking.map((s, k) => (
                                <option value={s.speaking_id}>
                                  {s.speaking_name}
                                </option>
                              ))}
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Select language
                            </span>
                          </label>
                        </div>
                        <div className=" col-sm-4 form-group">
                          <label className="mb-3 top-label">
                            <select
                              className="form-control"
                              id="language_level"
                              data-error="Please select total year experience"
                            >
                              <option value="">Select</option>
                              <option value="A1 - beginner level">
                                A1 - beginner level
                              </option>
                              <option value="A2 - elementary level">
                                A2 - elementary level
                              </option>
                              <option value="B1 - intermediate level">
                                B1 - intermediate level
                              </option>
                              <option value="B2 - upper intermediate level">
                                B2 - upper intermediate level
                              </option>
                              <option value="C1 - advanced level">
                                C1 - advanced level
                              </option>
                              <option value="C2 - highly competent level">
                                C2 - highly competent level
                              </option>
                            </select>
                            <span
                              style={{
                                color: "rgb(94, 92, 92)",
                                textTransform: "capitalize",
                              }}
                            >
                              Select your level
                            </span>
                          </label>
                        </div>
                        <div className=" col-sm-4 form-group">
                          <button
                            onClick={() => _addLanguageSkills()}
                            type="button"
                            className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                            data-bs-toggle="modal"
                            data-bs-target="#largeRightModalExample"
                          >
                            <i data-acorn-icon="plus"></i>
                            <span>Add</span>
                          </button>
                        </div>

                        <div style={{ clear: "both" }}>&nbsp;</div>
                        {languageSkills.map((l, k) => (
                          <div className="col-sm-3 form-group">
                            {l.name}
                            <br />
                            <span style={{ color: "rgb(142, 141, 141)" }}>
                              {l.level}
                            </span>
                          </div>
                        ))}
                      </div>

                      <h3 className="mb-2 pb-0 display-6 mt-4 pt-4" id="title">
                        Additional Note
                      </h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-12">
                          <textarea
                            id="additional_note"
                            className="form-control"
                            placeholder="Additional Note"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*<div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <h3 className="mb-2 pb-0 display-6" id="title">Hobbies</h3><hr />
                                            <div className='row'>
                                                {
                                                    hobbies.map((h, k) =>
                                                        <div className=" col-sm-3 form-group">
                                                            <div className="form-check mt-2">
                                                                <input type="checkbox" className="hobbies form-check-input" value={h.hobby_id} id={"hb_" + h.hobby_id} />
                                                                <label className="form-check-label" htmlFor={"hb_" + h.hobby_id} style={{ textTransform: 'capitalize' }}>{h.hobby_name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                            </div>*/}

                  <div className="d-grid gap-2 mb-3">
                    <button
                      onClick={(e) => save(e)}
                      className="btn btn-primary"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Page Content End */}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Profile;
